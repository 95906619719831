import { useState, useEffect } from 'react'
import { httpsCallable } from "firebase/functions"
import MapWithMarker from './components/MapWithMarker'
import CalendarInfo from './components/CalendarInfo'

export default function Thanks({functions}) {
    const [eventData, setEventData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const eventId = urlParams.get('eid')
        if (eventId) {
            setLoading(true)
            const getEventDetails = httpsCallable(functions, 'getEventForThanksPage')
            getEventDetails({ eventId }).then(result => {
                if (result.data === "invalid") {
                    setLoading(false)
                } else {
                    setEventData(result.data)
                    console.log(result.data)
                    setLoading(false)
                }
            }).catch(error => {
                console.error('Error getting event details:', error)
                setLoading(false)
            })
        }
    }, [functions])

    return (
        <div className="max-w-md mx-auto my-12 p-4">
            <h1 className="mt-2 text-2xl font-bold tracking-tight sm:text-3xl">Registration confirmed</h1>
            <p className="mt-2 text-lg text-neutral-500 dark:text-neutral-400">Details have been sent to your inbox. Thank you!</p>
            {loading && (
                <div className="mt-10 max-w-md mx-auto">
                    <p className="text-sm mb-1 text-neutral-500 dark:text-neutral-400">Loading...</p>
                </div>
            )}
            {!loading && eventData && eventData.calendarData && (
                <CalendarInfo 
                    calendarData={eventData.calendarData}
                    eventName={eventData.eventName}
                    locationName={eventData.locationName}
                    fullAddress={eventData.fullAddress}
                />
            )}
            {!loading && ((eventData && eventData.placeId && eventData.placeId.trim()) || 
                (eventData && eventData.locationCoordinates && 
                typeof eventData.locationCoordinates.lat === 'number' && 
                typeof eventData.locationCoordinates.lng === 'number')) && 
                eventData.locationName && (
                <div className="mt-10 max-w-md mx-auto">
                    <MapWithMarker 
                        placeId={eventData.placeId} 
                        coordinates={eventData.locationCoordinates} 
                        locationName={eventData.locationName}
                        fullAddress={eventData.fullAddress}
                    />
                </div>
            )}
        </div>
    )
}