import { useState, useEffect } from 'react'
import { httpsCallable } from 'firebase/functions'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function AuthorizedUsersManager({ uid, db, functions, userData }) {
    const [newUserEmail, setNewUserEmail] = useState('')
    const [isAdding, setIsAdding] = useState(false)
    const [removingEmail, setRemovingEmail] = useState(null)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    
    // Create local state for authorized users initialized from userData
    const [localAuthorizedUsers, setLocalAuthorizedUsers] = useState(userData?.authorizedUsers || [])
    
    // Update local state when userData changes
    useEffect(() => {
        if (userData?.authorizedUsers) {
            setLocalAuthorizedUsers(userData.authorizedUsers)
        }
    }, [userData?.authorizedUsers])
    
    // Auto-clear success message after 5 seconds
    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                setSuccess('')
            }, 5000)
            
            // Clean up the timer if component unmounts or success changes
            return () => clearTimeout(timer)
        }
    }, [success])
    
    // Add a new authorized user
    const addAuthorizedUser = async (e) => {
        e.preventDefault()
        
        if (!newUserEmail || !newUserEmail.includes('@')) {
            setError('Please enter a valid email address')
            return
        }
        
        setIsAdding(true)
        setError('')
        setSuccess('')
        
        try {
            // Call the Cloud Function instead of directly writing to Firestore
            const addAuthorizedUserFunction = httpsCallable(functions, 'addAuthorizedUser')
            const result = await addAuthorizedUserFunction({ newUserEmail })
            
            if (result.data === "success") {
                setSuccess(`${newUserEmail} was added as an authorized user and can now sign in at sidebarticketing.com`)
                setNewUserEmail('')
            } else {
                setError('Failed to add user. Please try again.')
            }
        } catch (error) {
            console.error('Error adding authorized user:', error)
            setError('Failed to add user. Please try again.')
        } finally {
            setIsAdding(false)
        }
    }
    
    // Remove an authorized user
    const removeAuthorizedUser = async (email) => {
        setRemovingEmail(email)
        setError('')
        setSuccess('')
        
        try {
            // Call the Cloud Function instead of directly deleting from Firestore
            const removeAuthorizedUserFunction = httpsCallable(functions, 'removeAuthorizedUser')
            const result = await removeAuthorizedUserFunction({ secondaryUserEmail: email })
            
            if (result.data === "success") {
                // Update local state to remove the user from the list
                setLocalAuthorizedUsers(prevUsers => prevUsers.filter(user => user.email !== email))
                setSuccess(`${email} was removed from authorized users`)
            } else {
                setError('Failed to remove user. Please try again.')
            }
        } catch (error) {
            console.error('Error removing authorized user:', error)
            setError('Failed to remove user. Please try again.')
        } finally {
            setRemovingEmail(null)
        }
    }
    
    return (
        <div className="space-y-4">
            <p className="font-medium text-stone-500 dark:text-stone-400">Authorized users</p>
            {/* Form to add new user */}
            <form onSubmit={addAuthorizedUser} className="mb-4">
                <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                    <div className="flex-grow relative">
                        <input
                            type="email"
                            value={newUserEmail}
                            onChange={(e) => setNewUserEmail(e.target.value)}
                            placeholder="Add user by email address"
                            className="w-full pl-3 pr-3 py-2 rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={isAdding}
                        className="inline-flex items-center justify-center text-white bg-teal-600 hover:bg-teal-700 px-4 py-2 rounded-md border border-teal-600 transition-colors"
                    >
                        {isAdding ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Adding...
                            </>
                        ) : (
                            <>
                                <PlusIcon className="w-4 h-4 mr-1.5" />
                                Add User
                            </>
                        )}
                    </button>
                </div>
                
                {(error || success) && (
                    <div className={`mt-2 text-sm ${error ? 'text-red-600' : 'text-teal-600'} bg-${error ? 'red' : 'teal'}-50 dark:bg-${error ? 'red' : 'teal'}-900/20 p-2 rounded-md`}>
                        {error || success}
                    </div>
                )}
            </form>
            
            {/* List of authorized users */}
            {localAuthorizedUsers.length > 0 ? (
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 overflow-hidden shadow-sm">
                    <ul className="divide-y divide-stone-100 dark:divide-stone-700">
                        {localAuthorizedUsers.map((user, index) => (
                            <li key={index} className="flex items-center justify-between p-3 hover:bg-stone-50 dark:hover:bg-stone-700/50 transition-colors">
                                <div>
                                    <div className="font-medium text-stone-800 dark:text-stone-200">{user.email}</div>
                                    <div className="text-xs text-stone-500 dark:text-stone-400 mt-0.5">
                                        Added {user.addedAt ? new Date(user.addedAt.toDate ? user.addedAt.toDate() : user.addedAt).toLocaleDateString() : 'recently'}
                                    </div>
                                </div>
                                <button
                                    onClick={() => removeAuthorizedUser(user.email)}
                                    className="p-1.5 text-stone-400 hover:text-red-600 rounded-full hover:bg-stone-100 dark:hover:bg-stone-700 transition-colors"
                                    title="Remove user"
                                    disabled={removingEmail === user.email}
                                >
                                    {removingEmail === user.email ? (
                                        <svg className="animate-spin h-5 w-5 text-stone-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    ) : (
                                        <XMarkIcon className="w-5 h-5" />
                                    )}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="text-center py-6 bg-stone-50 dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700">
                    <p className="text-stone-500 dark:text-stone-400 text-sm">No authorized users yet</p>
                </div>
            )}
        </div>
    )
} 