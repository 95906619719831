import { useState, useEffect } from 'react'
import { httpsCallable } from "firebase/functions"
import MapWithMarker from './components/MapWithMarker'
import CalendarInfo from './components/CalendarInfo'

export default function Success({functions}) {
    const [tickets, setTickets] = useState([])
    const [loading, setLoading] = useState(true)
    const [expired, setExpired] = useState(false)
    const [failReason, setFailReason] = useState(null)
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const getTickets = httpsCallable(functions, 'getTicketsForSuccessPage')
        getTickets({
            checkoutSessionId: urlParams.get('cid'),
            accountId: urlParams.get('aid'),
            connectPlatform: urlParams.get('cp')
        }).then(result => {
            if (result.data === "invalid" || result.data === "error") {
                setFailReason(result.data)
            } else if (result.data === "expired") {
                setExpired(true)
            } else {
                var QRCode = require('qrcode')
                const decodedTickets = []
                function b64_to_utf8(str) {
                    return decodeURIComponent(escape(window.atob(str)))
                }
                for (const ticket of result.data) {
                    const decoded = b64_to_utf8(ticket)
                    const parsedTicketObject = JSON.parse(decoded)
                    QRCode.toDataURL(parsedTicketObject.ticketId).then(code => {
                        decodedTickets.push({
                            ...parsedTicketObject,
                            ticketCode: code,
                            url: `https://sidebarticketing.com/ticket?t=${ticket}`
                        })
                        if (decodedTickets.length === result.data.length) {
                            setTickets(decodedTickets)
                        }
                    })
                }
            }
            setLoading(false)
        }).catch(error => {
            setFailReason("error")
            setLoading(false)
        })
    },[functions])

    return (
        <div className='h-full p-4'>
            <div className="mx-auto max-w-md mt-6 md:mt-12">
                <p className="mt-2 text-2xl font-bold tracking-tight sm:text-3xl">
                    {expired
                        ?
                            "Expired link"
                        :
                            "Order confirmed"
                    }
                </p>
                {loading
                    ?
                        <p className="mt-2 text-lg animate-pulse">Generating tickets...</p>
                    :
                        <p className="mt-2 text-lg text-neutral-500 dark:text-neutral-400">
                            {expired
                                ?
                                    "This ticketing order confirmation link has expired. At the time of purchase, links to your tickets were also sent to your e-mail address."
                                :
                                    "Your tickets have been sent to your inbox."
                            }
                        </p>
                }
                {failReason && 
                    <p className="mt-2 text-lg">
                        {failReason === "invalid" && "This URL appears to be invalid"}
                        {failReason === "error" && "There was an error fetching this page"}
                    </p>
                }
                {/* Add to Calendar button */}
                {tickets && tickets.length > 0 && tickets[0].calendarData && (
                    <div className="mt-6 border-t border-neutral-200 dark:border-neutral-700 pt-2">
                        <CalendarInfo 
                            calendarData={tickets[0].calendarData}
                            eventName={tickets[0].eventName}
                            locationName={tickets[0].locationName}
                            fullAddress={tickets[0].fullAddress}
                            placeId={tickets[0].placeId}
                            coordinates={tickets[0].locationCoordinates}
                        />
                    </div>
                )}
            </div>
            {!failReason && !loading && 
                <div className='mt-6'>
                    {tickets.map(ticket => (
                        <div className="flex flex-col mx-auto max-w-md border-t border-neutral-200 dark:border-neutral-700 mt-2 py-2" key={ticket.ticketId}>
                            <div className="flex flex-col">
                                <p className="text-xl font-bold">{ticket.ticketName}</p>
                                <p className="text-sm font-light">{ticket.ticketId}</p>
                            </div>
                            <a
                                className="rounded-2xl p-2 font-semibold shadow-sm flex-shrink-0 mt-2 text-center bg-neutral-700 text-white dark:bg-neutral-300 dark:text-black"
                                href={ticket.url} target="_blank" rel="noreferrer"
                            >
                                View & Save Ticket
                            </a>
                        </div>
                    ))}
                    {((tickets && tickets[0] && tickets[0].placeId && tickets[0].placeId.trim()) || 
                        (tickets && tickets[0] && tickets[0].locationCoordinates && 
                        typeof tickets[0].locationCoordinates.lat === 'number' && 
                        typeof tickets[0].locationCoordinates.lng === 'number')) && 
                        tickets[0].locationName && (
                        <div className="mt-10 max-w-md mx-auto">
                            <MapWithMarker 
                                placeId={tickets[0].placeId} 
                                coordinates={tickets[0].locationCoordinates} 
                                locationName={tickets[0].locationName}
                                fullAddress={tickets[0].fullAddress}
                            />
                        </div>
                    )}                     
                    <footer className='flex flex-col items-center'>
                        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                            <div className="mt-8 md:order-1 md:mt-0">
                                <p className="text-center text-xs leading-5 text-neutral-500 dark:text-neutral-400">
                                    &copy; Sidebar Ticketing, LLC. All rights reserved. <br /> Use <a href="https://sidebarticketing.com" alt="sidebar ticketing" className='underline'>Sidebar Ticketing</a> for your events.
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
            }
        </div>
    )
}