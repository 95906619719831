import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Footer from './components/Footer'

const currencies = [
    {code: "USD", name: "United States Dollar", price:"$0.79"},
    {code: "CAD", name: "Canadian Dollar", price:"$1.10"},
    {code: "EUR", name: "Euro", price:"€0.79"},
    {code: "GBP", name: "British Pound", price:"£0.70"},
    {code: "AUD", name: "Australian Dollar", price:"$1.20"},
    {code: "NZD", name: "New Zealand Dollar", price:"$1.30"},
    {code: "DKK", name: "Danish Krone", price:"kr5.50"},
    {code: "INR", name: "Indian Rupee", price:"₹70"},
    {code: "RON", name: "Romanian Leu", price:"lei3.79"},
    {code: "CHF", name: "Swiss Franc", price:"CHF0.79"},
    {code: "ZAR", name: "South African Rand", price:"R18"},
    {code: "MXN", name: "Mexican Peso", price:"$15"},
    {code: "RUB", name: "Russian Ruble", price:"₽79"},
    {code: "BGN", name: "Bulgarian Lev", price:"лв1.50"},
    {code: "SGD", name: "Singapore Dollar", price:"$1.10"},
    {code: "SEK", name: "Swedish Krona", price:"kr9"},
    {code: "NOK", name: "Norwegian Krone", price:"kr9"},
    {code: "PLN", name: "Polish Zloty", price:"zł3.30"},
    {code: "AED", name: "Emirati Dirham", price:"3.00"},
]

export default function Pricing() {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <section className='pt-20 lg:pt-24 '>
                <div className='w-10/12 max-w-3xl mx-auto text-center'>
                    <img className="h-9 lg:h-12 mb-12 sidebar-logo mx-auto" alt="sidebar-ticketing-logo" />
                    <span className="text-[4rem] tracking-tight font-bold leading-[1.2] text-teal-600 dark:text-teal-400">
                        $0.79
                    </span>
                    <span className="text-xl text-stone-600 dark:text-stone-400 ml-2">
                        per ticket
                    </span>
                    <p className="mb-4 text-small"><button className="underline cursor-pointer" onClick={() => setOpen(true)}>View in other currencies</button></p>
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={setOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-black p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">                                     
                                        <div className="text-center">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                                Per ticket pricing
                                            </Dialog.Title>
                                        </div>
                                        <ul className="divide-y divide-stone-100 dark:divide-stone-800 mt-2">
                                            {currencies.map((currency) => (
                                                <li key={currency.code} className="flex justify-between py-2">
                                                    <div className="flex gap-1 items-center">
                                                        <p className="text-sm font-semibold">{currency.code}</p>
                                                        <p className="truncate text-xs text-stone-600 dark:text-stone-300">{currency.name}</p>
                                                    </div>
                                                    <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm">{currency.price}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <p className='mt-2 text-xs text-stone-600 dark:text-stone-300'>Your currency missing? If you can use it in Stripe, you can use it in Sidebar Ticketing. You'll be charged at a reasonable conversion from USD.</p>
                                        <div className="mt-5 sm:mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                onClick={() => setOpen(false)}
                                            >
                                                Dismiss
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>
            </section>

            <section>
                <div className='w-11/12 max-w-2xl mx-auto my-8'>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <svg className="h-5 w-5 text-teal-500 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <span className="text-stone-700 dark:text-stone-300">Pass the fee on to your buyers</span>
                        </div>
                        <div className="flex items-center">
                            <svg className="h-5 w-5 text-teal-500 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <span className="text-stone-700 dark:text-stone-300">Free tickets are free</span>
                        </div>
                        <div className="flex items-center">
                            <svg className="h-5 w-5 text-teal-500 mr-3" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <span className="text-stone-700 dark:text-stone-300">No setup costs or hidden fees</span>
                        </div>
                    </div>
                    
                    <div className="mt-8 text-stone-600 dark:text-stone-400">
                        <p>
                        We will help you set up a Stripe account for payment processing. Stripe's fees (typically 2.9% + $0.30 per transaction), are separate from our fee. You can charge buyers a processing fee to cover this.
                        </p>
                    </div>
                    
                    <div className="mt-12 text-center">
                        <a 
                            href="/signin" 
                            className="inline-block py-3 px-8 rounded-lg text-white bg-teal-600 hover:bg-teal-500 font-semibold 
                            transition duration-150 ease-in-out transform hover:scale-[1.02] 
                            focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                        >
                            Get Started
                        </a>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
} 