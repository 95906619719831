import { useState } from 'react'
import { PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import { httpsCallable } from "firebase/functions"

export default function CompTicketButton({ eventId, events, functions }) {
    const [showSendTicket, setShowSendTicket] = useState(false)
    const [selectedTicketType, setSelectedTicketType] = useState(null)
    const [recipientEmail, setRecipientEmail] = useState('')
    const [recipientName, setRecipientName] = useState('')
    const [isSending, setIsSending] = useState(false)
    const [sendStatus, setSendStatus] = useState({ type: null, message: null })
    
    const handleSendTicket = async () => {
        if (!selectedTicketType || !recipientEmail || !recipientName) return
        
        setIsSending(true)
        setSendStatus({ type: null, message: null })
        try {
            const compTicket = httpsCallable(functions, 'compTicket')
            await compTicket({
                eventId,
                ticketTypeName: selectedTicketType.name,
                productId: selectedTicketType.productId,
                buyerEmail: recipientEmail,
                buyerName: recipientName
            })
            setSendStatus({
                type: 'success',
                message: 'Ticket sent successfully!'
            })
        } catch (error) {
            setSendStatus({
                type: 'error',
                message: error.message || 'Failed to send ticket. Please try again.'
            })
        } finally {
            setIsSending(false)
        }
    }

    return (
        <>
            <button
                onClick={() => setShowSendTicket(true)}
                className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
            >
                <PaperAirplaneIcon className="h-3.5 w-3.5" />
                Comp
            </button>

            <Dialog open={showSendTicket} onClose={() => setShowSendTicket(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-stone-900 p-6 w-full">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">Send Free Ticket</Dialog.Title>
                            <button onClick={() => setShowSendTicket(false)}>
                                <XMarkIcon className="h-3.5 w-3.5" />
                            </button>
                        </div>

                        <div className="space-y-4">
                            {!sendStatus.message ? (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Select Ticket Type
                                        </label>
                                        <select
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={selectedTicketType?.productId || ''}
                                            onChange={(e) => {
                                                const ticketType = events?.find(ev => ev.paymentLinkId === eventId)
                                                    ?.ticketTypes?.find(tt => tt.productId === e.target.value)
                                                setSelectedTicketType(ticketType)
                                            }}
                                        >
                                            <option value="">Select a ticket type...</option>
                                            {events?.find(e => e.paymentLinkId === eventId)?.ticketTypes?.map(ticketType => (
                                                <option key={ticketType.productId} value={ticketType.productId}>
                                                    {ticketType.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Recipient Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={recipientName}
                                            onChange={(e) => setRecipientName(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Recipient Email
                                        </label>
                                        <input
                                            type="email"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={recipientEmail}
                                            onChange={(e) => setRecipientEmail(e.target.value)}
                                        />
                                        <p className="mt-1 text-sm text-stone-500 dark:text-stone-400">
                                            Send directly to the e-mail address of the intended recipient, or to your own e-mail address if you plan on forwarding.
                                        </p>
                                    </div>

                                    <button
                                        onClick={handleSendTicket}
                                        disabled={!selectedTicketType || !recipientEmail || !recipientName || isSending}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {isSending ? 'Sending...' : 'Send Ticket'}
                                    </button>
                                    
                                    <p className="text-sm text-stone-500 dark:text-stone-400 text-center">
                                        Please note that this form will let you issue tickets beyond your listed capacity.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className={`p-3 rounded-md ${
                                        sendStatus.type === 'success' 
                                            ? 'bg-green-50 text-green-700 dark:bg-green-900/50 dark:text-green-400' 
                                            : 'bg-red-50 text-red-700 dark:bg-red-900/50 dark:text-red-400'
                                    }`}>
                                        {sendStatus.message}
                                    </div>
                                    <button
                                        onClick={() => {
                                            setShowSendTicket(false)
                                            setSelectedTicketType(null)
                                            setRecipientEmail('')
                                            setRecipientName('')
                                            setSendStatus({ type: null, message: null })
                                        }}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700"
                                    >
                                        Done
                                    </button>
                                </>
                            )}
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    )
} 