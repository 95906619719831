import { Link } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'

export default function EmptyState({ stripeAccountId, userData }) {
    const [isLoading, setIsLoading] = useState(false)

    // Check if the user needs to complete Stripe onboarding
    const needsStripeOnboarding = userData?.connectPlatform === 2 && (
        userData?.stripeConnectHasCurrentlyDue || 
        userData?.stripeConnectHasEventuallyDue || 
        userData?.stripeConnectHasPastDue || 
        !userData?.stripeConnectChargesEnabled
    )

    const handleStripeConnect = async () => {
        try {
            setIsLoading(true)
            const functions = getFunctions()
            const createStripeHostedOnboardingLink = httpsCallable(functions, 'createStripeHostedOnboardingLink')
            const { data } = await createStripeHostedOnboardingLink({
                stripeAccountId: stripeAccountId
            })
            
            if (data.url) {
                window.location.href = data.url
                return
            }
            setIsLoading(false)
        } catch (error) {
            console.error('Error creating Stripe account link:', error)
            setIsLoading(false)
        }
    }

    return (
        <div className="h-full flex flex-col justify-center items-center">
            <div>
                <h1 className="text-4xl tracking-tight font-bold sm:text-7xl">
                    Welcome to&nbsp;Sidebar&nbsp;👋
                </h1>
                <div className="mt-8 flex flex-col sm:flex-row gap-4">
                    <Link
                        to="/events/new"
                        className="inline-flex items-center px-6 py-3 rounded-lg text-white bg-teal-500 hover:bg-teal-600 
                                transition duration-150 ease-in-out transform hover:scale-[1.02] 
                                focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                    >
                        <svg
                            className="mr-2 h-5 w-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4v16m8-8H4"
                            />
                        </svg>
                        Create Your First Event
                    </Link>

                    {needsStripeOnboarding && (
                        <button
                            onClick={handleStripeConnect}
                            disabled={isLoading}
                            className="inline-flex items-center px-6 py-3 rounded-lg text-stone-700 bg-stone-200 hover:bg-stone-300
                                transition duration-150 ease-in-out
                                focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2
                                disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-stone-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Connecting...
                                </>
                            ) : (
                                'Connect Payment Account'
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}