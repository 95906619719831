import { useState } from 'react'
import { DocumentDuplicateIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import { httpsCallable } from "firebase/functions"
import { useNavigate } from 'react-router-dom'

export default function DuplicateButton({ eventId, functions }) {
    const navigate = useNavigate()
    const [isDuplicating, setIsDuplicating] = useState(false)
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
    const [newEventName, setNewEventName] = useState('')
    const [duplicateStatus, setDuplicateStatus] = useState({ type: null, message: null })

    const handleDuplicate = async () => {
        if (!newEventName.trim()) return
        
        setIsDuplicating(true)
        setDuplicateStatus({ type: null, message: null })
        
        try {
            const duplicateEventFn = httpsCallable(functions, 'duplicateEvent')
            await duplicateEventFn({ eventId, newEventName: newEventName.trim() })
            setDuplicateStatus({
                type: 'success',
                message: 'All set!'
            })
            setTimeout(() => {
                setShowDuplicateDialog(false)
                navigate(`/events`)
            }, 2000)
        } catch (error) {
            setDuplicateStatus({
                type: 'error',
                message: error.message || 'Failed to duplicate event. Please try again.'
            })
            setIsDuplicating(false)
        }
    }

    return (
        <>
            <button
                onClick={() => setShowDuplicateDialog(true)}
                className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
                disabled={isDuplicating}
            >
                {isDuplicating ? (
                    <div className="animate-spin h-3.5 w-3.5 border-b-2 border-current" />
                ) : (
                    <DocumentDuplicateIcon className="h-3.5 w-3.5" />
                )}
                {isDuplicating ? 'Duplicating...' : 'Duplicate'}
            </button>

            <Dialog open={showDuplicateDialog} onClose={() => setShowDuplicateDialog(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-stone-900 p-6 w-full">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">Duplicate Event</Dialog.Title>
                            <button onClick={() => setShowDuplicateDialog(false)}>
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-4">
                            {!duplicateStatus.message ? (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            New Event Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={newEventName}
                                            onChange={(e) => setNewEventName(e.target.value)}
                                            placeholder="Enter new event name"
                                            disabled={isDuplicating}
                                        />
                                    </div>

                                    <button
                                        onClick={handleDuplicate}
                                        disabled={!newEventName.trim() || isDuplicating}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {isDuplicating ? 'Duplicating...' : 'Duplicate Event'}
                                    </button>
                                </>
                            ) : (
                                <div className={`p-3 rounded-md ${
                                    duplicateStatus.type === 'success' 
                                        ? 'bg-green-50 text-green-700 dark:bg-green-900/50 dark:text-green-400' 
                                        : 'bg-red-50 text-red-700 dark:bg-red-900/50 dark:text-red-400'
                                }`}>
                                    {duplicateStatus.message}
                                </div>
                            )}
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    )
} 