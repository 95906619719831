import { useState } from 'react'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import pica from 'pica'

export default function BasicDetails({ register, setValue, watch, uid, errors }) {
    const [isUploading, setIsUploading] = useState(false)
    const [imageWidth, setImageWidth] = useState(0)
    const eventImageUrl = watch('eventImageUrl') // Watch the image URL field
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (!file) return

        // Check image dimensions
        if (file.type.startsWith('image/')) {
            const tempUrl = URL.createObjectURL(file)
            const img = new Image()
            img.onload = async () => {
                setImageWidth(img.width)
                URL.revokeObjectURL(tempUrl)
                
                if (img.width >= 512) {
                    await uploadImage(file)
                }
            }
            img.src = tempUrl
        }
    }

    const resizeImage = async (file) => {
        const targetWidth = 840
        const reader = new FileReader()
        const canvas = document.createElement('canvas')
        const picaResizer = pica()
    
        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                const img = new Image()
                img.onload = async () => {
                    const aspectRatio = img.height / img.width
                    const targetHeight = targetWidth * aspectRatio
                    canvas.width = targetWidth
                    canvas.height = targetHeight
                    await picaResizer.resize(img, canvas)
                        .then(result => picaResizer.toBlob(result, 'image/jpeg', 0.90))
                        .then(blob => resolve(blob))
                        .catch(error => reject(error))
                }
                img.src = event.target.result
            }
            reader.readAsDataURL(file)
        })
    }

    const uploadImage = async (file) => {
        setIsUploading(true)
        try {
            const resizedImage = await resizeImage(file)
            const storage = getStorage()
            const storageRef = ref(storage, `uploads/${uid}/${file.name}`)
            const snapshot = await uploadBytes(storageRef, resizedImage)
            const downloadURL = await getDownloadURL(snapshot.ref)
            setValue('eventImageUrl', downloadURL) // Update the form value
        } catch (error) {
            console.error('Upload failed', error)
        } finally {
            setIsUploading(false)
        }
    }

    return (
        <div className="space-y-2">
            <hr className="my-4 dark:border-stone-700" />
            <div className="sm:col-span-2">
                <label>Event name*</label>
                <div className="mt-1">
                    <input
                        type="text"
                        {...register('eventName')}
                        className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventName ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                    />
                    {errors.eventName && (
                        <p className="mt-2 text-sm text-red-600">
                            {errors.eventName.message}
                        </p>
                    )}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label>Description</label>
                <div className="mt-1">
                    <textarea
                        rows={4}
                        {...register('eventDescription')}
                        className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventDescription ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                    />
                    {errors.eventDescription && (
                        <p className="mt-2 text-sm text-red-600">
                            {errors.eventDescription.message}
                        </p>
                    )}
                    <p className="mt-2 text-xs text-stone-500">
                        Markdown, emojis, and line breaks are supported. The format for links is: <code>[Link text](https://example.com)</code>
                    </p>
                </div>
            </div>
            <div className="pt-2">
                {eventImageUrl ? (
                    <div className="flex justify-start gap-4">
                        <img 
                            src={eventImageUrl} 
                            alt="Event preview" 
                            className="h-48 object-contain rounded-lg"
                        />
                        <button
                            onClick={() => setValue('eventImageUrl', '')}
                            className="p-1 bg-white dark:bg-stone-900 rounded-full hover:bg-stone-100 dark:hover:bg-stone-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-stone-600 dark:text-stone-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                ) : (
                    <div>
                        {isUploading ? (
                            <div className="text-teal-600 border border-teal-600 rounded-md p-2 inline-flex items-center gap-2">
                                <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Uploading...
                            </div>
                        ) : (
                            <label htmlFor="event-image" className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 inline-flex items-center gap-2 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                Event image
                                <input
                                    id="event-image"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                            </label>
                        )}
                        {imageWidth > 0 && imageWidth < 512 && (
                            <p className="text-red-500 text-sm mt-2">Image must be at least 512px wide. Current width: {imageWidth}px</p>
                        )}
                    </div>
                )}
                <input type="hidden" {...register('eventImageUrl')} />
            </div>
        </div>
    )
}