import { Bars2Icon, PencilSquareIcon, DocumentDuplicateIcon, ArchiveBoxIcon, ShoppingCartIcon, ArrowTopRightOnSquareIcon, PauseCircleIcon, ArrowRightStartOnRectangleIcon, PlayCircleIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { httpsCallable } from "firebase/functions"
import { doc, updateDoc } from 'firebase/firestore'

export default function EventMenu({ eventId, menuId, issued, auth, uid, customTrigger, functions, events, db, chargesEnabled, hasPaidTickets, onEventPage }) {
    const navigate = useNavigate()
    const [showSendTicket, setShowSendTicket] = useState(false);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [recipientEmail, setRecipientEmail] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [sendStatus, setSendStatus] = useState({ type: null, message: null });
    const [isArchived, setIsArchived] = useState(false);
    const [isTicketingLive, setIsTicketingLive] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
    const [newEventName, setNewEventName] = useState('')
    const [duplicateStatus, setDuplicateStatus] = useState({ type: null, message: null })

    useEffect(() => {
        function closeMenu(event) {
            const menu = document.getElementById(menuId)
            const menuButton = event.target.closest(`button[data-dropdown="${menuId}"]`)
            
            if (menu && !menu.contains(event.target) && !menuButton) {
                menu.classList.add('hidden')
            }
        }

        document.addEventListener('click', closeMenu)
        
        // Add click listener for the trigger button to toggle menu
        const triggerButton = document.querySelector(`button[data-dropdown="${menuId}"]`)
        if (triggerButton) {
            triggerButton.addEventListener('click', (e) => {
                e.preventDefault()
                const menu = document.getElementById(menuId)
                if (menu) {
                    menu.classList.toggle('hidden')
                }
            })
        }
        
        return () => {
            document.removeEventListener('click', closeMenu)
            const triggerButton = document.querySelector(`button[data-dropdown="${menuId}"]`)
            if (triggerButton) {
                triggerButton.removeEventListener('click', () => {})
            }
        }
    }, [menuId])

    useEffect(() => {
        const event = events?.find(e => e.paymentLinkId === eventId)
        setIsArchived(!event)
        setIsTicketingLive(!event ? false : event.isTicketingLive)
    }, [events, eventId])

    const closeEventMenu = () => {
        const menu = document.getElementById(menuId)
        if (menu) {
            menu.classList.add('hidden')
        }
    }

    const handleSendTicket = async () => {
        closeEventMenu()
        if (!selectedTicketType || !recipientEmail || !recipientName) return
        
        setIsSending(true)
        setSendStatus({ type: null, message: null })
        try {
            const compTicket = httpsCallable(functions, 'compTicket')
            await compTicket({
                eventId,
                ticketTypeName: selectedTicketType.name,
                productId: selectedTicketType.productId,
                buyerEmail: recipientEmail,
                buyerName: recipientName
            });
            setSendStatus({
                type: 'success',
                message: 'Ticket sent successfully!'
            });
        } catch (error) {
            setSendStatus({
                type: 'error',
                message: error.message || 'Failed to send ticket. Please try again.'
            })
        } finally {
            setIsSending(false)
        }
    }

    const handleArchiveToggle = async () => {
        try {
            const eventRef = doc(db, 'events', eventId)
            await updateDoc(eventRef, {
                archived: !isArchived,
                ...(!isArchived && {isTicketingLive: false})
            })
            setIsArchived(!isArchived)
            closeEventMenu()
        } catch (error) {
            console.error('Error toggling archive status:', error)
        }
    }

    const handleSalesLiveToggle = async () => {
        if (!chargesEnabled && hasPaidTickets) {
            alert('Go to account settings to enable payments')
            return
        }
        try {
            const eventRef = doc(db, 'events', eventId)
            await updateDoc(eventRef, {
                isTicketingLive: !isTicketingLive
            })
            setIsTicketingLive(!isTicketingLive)
            closeEventMenu()
        } catch (error) {
            console.error('Error toggling sales live status:', error)
        }
    }

    const handleDuplicate = async () => {
        if (!newEventName.trim()) return
        
        setIsDuplicating(true)
        setDuplicateStatus({ type: null, message: null })
        
        try {
            const duplicateEventFn = httpsCallable(functions, 'duplicateEvent')
            await duplicateEventFn({ eventId, newEventName: newEventName.trim() })
            setDuplicateStatus({
                type: 'success',
                message: 'All set!'
            })
            setTimeout(() => {
                setShowDuplicateDialog(false)
                navigate(`/events`)
            }, 2000)
        } catch (error) {
            setDuplicateStatus({
                type: 'error',
                message: error.message || 'Failed to duplicate event. Please try again.'
            })
            setIsDuplicating(false)
        }
    }

    return (
        <div className="relative">
            {customTrigger || (
                <button
                    type="button"
                    className="flex items-center text-white font-semibold bg-teal-700 hover:opacity-90 border border-teal-700 rounded-md px-4 py-2 w-full sm:w-auto mt-3 md:mt-0"
                    disabled={isDuplicating}
                    data-dropdown={menuId}
                >
                    {isDuplicating ? (
                        <div className="animate-spin h-5 w-5 mr-2 border-b-2 border-current" />
                    ) : (
                        <Bars2Icon className="h-5 w-5 mr-2" />
                    )}
                    {isDuplicating ? 'Duplicating...' : 'Event Menu'}
                </button>
            )}
            <div
                id={menuId}
                className="hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-stone-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                {isArchived ? (
                    <button 
                        className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                        onClick={() => {
                            closeEventMenu()
                            handleArchiveToggle()
                        }}
                    >
                        <ArchiveBoxIcon className="h-5 w-5 mr-3" />
                        Unarchive
                    </button>
                ) : (
                    <>
                        {customTrigger && !onEventPage && (
                            <Link
                                to={`/events/${eventId}`}
                                className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                                onClick={closeEventMenu}
                            >
                                <ArrowRightStartOnRectangleIcon className="h-5 w-5 mr-3" />
                                View
                            </Link>
                        )}
                        <Link
                            to={`/events/${eventId}/edit`}
                            className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                            onClick={closeEventMenu}
                        >
                            <PencilSquareIcon className="h-5 w-5 mr-3" />
                            Edit
                        </Link>
                        <button 
                            className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                            onClick={(e) => {
                                e.stopPropagation()
                                closeEventMenu()
                                setShowDuplicateDialog(true)
                            }}
                            disabled={isDuplicating}
                        >
                            <DocumentDuplicateIcon className="h-5 w-5 mr-3" />
                            {isDuplicating ? 'Duplicating...' : 'Duplicate'}
                        </button>
                        <button 
                            className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                            onClick={() => {
                                closeEventMenu()
                                handleArchiveToggle()
                            }}
                        >
                            <ArchiveBoxIcon className="h-5 w-5 mr-3" />
                            Archive
                        </button>
                        <div className="border-t border-stone-100 dark:border-stone-800 mt-1 pt-1">
                            <button 
                                className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                                onClick={() => {
                                    closeEventMenu()
                                    handleSalesLiveToggle()
                                }}
                            >
                                {isTicketingLive ? (
                                    <PauseCircleIcon className="h-5 w-5 mr-3" />
                                ) : (
                                    <PlayCircleIcon className="h-5 w-5 mr-3" />
                                )}
                                {isTicketingLive ? 'Pause Sales' : 'Resume Sales'}
                            </button>
                            {!onEventPage && 
                            <a
                                href={`https://tkt.page/${eventId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                                onClick={closeEventMenu}
                            >
                                <ArrowTopRightOnSquareIcon className="h-5 w-5 mr-3" />
                                View Public Page
                            </a>
                            }
                            <button 
                                className="flex items-center px-4 py-2 text-sm hover:bg-stone-100 dark:hover:bg-stone-800 w-full"
                                onClick={() => {
                                    closeEventMenu()
                                    navigate(`/orders?event=${eventId}`)
                                }}
                            >
                                <ShoppingCartIcon className="h-5 w-5 mr-3" />
                                View Orders
                            </button>
                        </div>
                    </>
                )}
            </div>
            <Dialog open={showSendTicket} onClose={() => setShowSendTicket(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-stone-900 p-6 w-full">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">Send Free Ticket</Dialog.Title>
                            <button onClick={() => setShowSendTicket(false)}>
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-4">
                            {!sendStatus.message ? (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Select Ticket Type
                                        </label>
                                        <select
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={selectedTicketType?.productId || ''}
                                            onChange={(e) => {
                                                const ticketType = events?.find(ev => ev.paymentLinkId === eventId)
                                                    ?.ticketTypes?.find(tt => tt.productId === e.target.value);
                                                setSelectedTicketType(ticketType);
                                            }}
                                        >
                                            <option value="">Select a ticket type...</option>
                                            {events?.find(e => e.paymentLinkId === eventId)?.ticketTypes?.map(ticketType => (
                                                <option key={ticketType.productId} value={ticketType.productId}>
                                                    {ticketType.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Recipient Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={recipientName}
                                            onChange={(e) => setRecipientName(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            Recipient Email
                                        </label>
                                        <input
                                            type="email"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={recipientEmail}
                                            onChange={(e) => setRecipientEmail(e.target.value)}
                                        />
                                        <p className="mt-1 text-sm text-stone-500 dark:text-stone-400">
                                            Send directly to the e-mail address of the intended recipient, or to your own e-mail address if you plan on forwarding.
                                        </p>
                                    </div>

                                    <button
                                        onClick={handleSendTicket}
                                        disabled={!selectedTicketType || !recipientEmail || !recipientName || isSending}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {isSending ? 'Sending...' : 'Send Ticket'}
                                    </button>
                                    
                                    <p className="text-sm text-stone-500 dark:text-stone-400 text-center">
                                        Please note that this form will let you issue tickets beyond your listed capacity.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className={`p-3 rounded-md ${
                                        sendStatus.type === 'success' 
                                            ? 'bg-green-50 text-green-700' 
                                            : 'bg-red-50 text-red-700'
                                    }`}>
                                        {sendStatus.message}
                                    </div>
                                    <button
                                        onClick={() => {
                                            setShowSendTicket(false);
                                            setSelectedTicketType(null);
                                            setRecipientEmail('');
                                            setRecipientName('');
                                            setSendStatus({ type: null, message: null });
                                        }}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700"
                                    >
                                        Done
                                    </button>
                                </>
                            )}
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
            <Dialog open={showDuplicateDialog} onClose={() => setShowDuplicateDialog(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-stone-900 p-6 w-full">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">Duplicate Event</Dialog.Title>
                            <button onClick={() => setShowDuplicateDialog(false)}>
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-4">
                            {!duplicateStatus.message ? (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium mb-1">
                                            New Event Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                            value={newEventName}
                                            onChange={(e) => setNewEventName(e.target.value)}
                                            placeholder="Enter new event name"
                                            disabled={isDuplicating}
                                        />
                                    </div>

                                    <button
                                        onClick={handleDuplicate}
                                        disabled={!newEventName.trim() || isDuplicating}
                                        className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {isDuplicating ? 'Duplicating...' : 'Duplicate Event'}
                                    </button>
                                </>
                            ) : (
                                <div className={`p-3 rounded-md ${
                                    duplicateStatus.type === 'success' 
                                        ? 'bg-green-50 text-green-700 dark:bg-green-900/50 dark:text-green-400' 
                                        : 'bg-red-50 text-red-700 dark:bg-red-900/50 dark:text-red-400'
                                }`}>
                                    {duplicateStatus.message}
                                </div>
                            )}
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    )
}