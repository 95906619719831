import { useContext, useRef, useEffect } from 'react'
import { GoogleMap, LoadScript, MapContext } from '@react-google-maps/api'

// Custom AdvancedMarker component
const AdvancedMarker = ({ position, locationName }) => {
    const map = useContext(MapContext)
    const markerRef = useRef(null)
    
    useEffect(() => {
        if (!map || !window.google) return
        
        // Create the advanced marker
        if (!markerRef.current) {
            // Make sure the marker library is loaded
            if (!window.google.maps.marker || !window.google.maps.marker.AdvancedMarkerElement) {
                console.error('Marker library not loaded. Add libraries=["marker"] to LoadScript')
                return
            }
            
            // Create a custom marker element with label
            const markerElement = document.createElement('div')
            markerElement.className = 'custom-marker'
            markerElement.style.display = 'flex'
            markerElement.style.flexDirection = 'column'
            markerElement.style.alignItems = 'center'
            
            // Create pin element
            const pinElement = document.createElement('div')
            pinElement.style.backgroundColor = '#4285F4'
            pinElement.style.borderRadius = '50% 50% 50% 0'
            pinElement.style.height = '24px'
            pinElement.style.width = '24px'
            pinElement.style.transform = 'rotate(-45deg)'
            pinElement.style.margin = '0 auto'
            
            // Create the inner circle
            const pinInner = document.createElement('div')
            pinInner.style.backgroundColor = 'white'
            pinInner.style.borderRadius = '50%'
            pinInner.style.height = '12px'
            pinInner.style.width = '12px'
            pinInner.style.margin = '6px'
            
            pinElement.appendChild(pinInner)
            markerElement.appendChild(pinElement)
            
            // Add label if location name exists
            if (locationName) {
                const label = document.createElement('div')
                label.textContent = locationName
                label.style.backgroundColor = 'white'
                label.style.color = 'black'
                label.style.padding = '4px 8px'
                label.style.borderRadius = '4px'
                label.style.marginTop = '8px'
                label.style.fontSize = '12px'
                label.style.fontWeight = 'bold'
                label.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)'
                markerElement.appendChild(label)
            }
            
            markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
                position,
                map,
                content: markerElement,
                title: locationName || ''
            })
        } else {
            // Update position if marker already exists
            markerRef.current.position = position
            markerRef.current.map = map
        }
        
        // Cleanup
        return () => {
            if (markerRef.current) {
                markerRef.current.map = null
            }
        }
    }, [map, position, locationName])
    
    return null
}

const MapWithMarker = ({ placeId, coordinates, locationName, fullAddress }) => {
    const mapStyles = {
        height: "200px",
        width: "100%",
        borderRadius: "0.8rem"
    }
    
    // Make sure we have valid coordinates before using them
    const hasValidCoordinates = coordinates && typeof coordinates.lat === 'number' && typeof coordinates.lng === 'number'
    
    const defaultCenter = hasValidCoordinates ? 
        { lat: coordinates.lat, lng: coordinates.lng } : 
        { lat: 40.7128, lng: -74.006 } // NYC default
    
    const locationUrl = placeId 
        ? `https://www.google.com/maps/place/?q=place_id:${placeId}`
        : hasValidCoordinates 
            ? `https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`
            : null
        
    return (
        <div className="map-container">
            {fullAddress && (
                <p className="text-sm mb-1 text-neutral-500 dark:text-neutral-400">{fullAddress}</p>
            )}
            <LoadScript 
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                loadingElement={<div className="text-center p-4">Loading map...</div>}
                onError={(error) => console.error('Google Maps loading error:', error)}
                libraries={["marker"]}
            >
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={15}
                    center={defaultCenter}
                    options={{
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        mapId: "map" // Required for advanced markers
                    }}
                >
                    {hasValidCoordinates && 
                        <AdvancedMarker 
                            position={defaultCenter} 
                            locationName={locationName}
                        />
                    }
                </GoogleMap>
            </LoadScript>
            {locationUrl && (
                <div className="text-right mt-1">
                    <a 
                        href={locationUrl}
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-sm text-neutral-700 dark:text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-100"
                    >
                        View in Google Maps
                    </a>
                </div>
            )}
        </div>
    )
}

export default MapWithMarker 