/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { useEffect } from 'react'
import EventMenu from './EventMenu'

export default function EventCard({ event, functions, events, db, chargesEnabled }) {
    const navigate = useNavigate()

    useEffect(() => {
        function handleClickOutside(event) {
            const dropdown = document.getElementById(`eventActions-${event.paymentLinkId}`)
            if (dropdown && !event.target.closest(`button[data-dropdown="${event.paymentLinkId}"]`)) {
                dropdown.classList.add('hidden')
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [event.paymentLinkId])

    return (
        <li className="flex hover:bg-stone-50/50 dark:hover:bg-stone-900/50 cursor-pointer border-t border-stone-200 dark:border-stone-800 transition-colors duration-150">
            <div 
                onClick={() => navigate(`/events/${event.paymentLinkId}`)}
                className="flex-1 flex items-center gap-x-4 py-3 px-2"
            >
                <div className="flex-shrink-0">
                    {event.eventImageUrl ? (
                        <img
                            src={event.eventImageUrl}
                            alt={event.eventName}
                            className="h-14 w-14 rounded-lg object-cover"
                        />
                    ) : (
                        <div className="h-14 w-14 rounded-lg bg-stone-100 dark:bg-stone-800 flex items-center justify-center">
                            <span className="text-xl font-medium text-stone-500 dark:text-stone-400">
                                {event.eventName.charAt(0)}
                            </span>
                        </div>
                    )}
                </div>
                <div className="flex flex-1 min-w-0 justify-between items-start">
                    <div className="space-y-1">
                        <div className="flex items-center gap-x-2">
                            <span className="font-medium text-stone-800 dark:text-stone-200">{event.eventName}</span>
                            {!event.livemode && (
                                <span className="inline-flex items-center rounded-md bg-orange-50/70 px-1.5 py-0.5 text-xs text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                    test
                                </span>
                            )}
                            
                            {/* Status indicator - small dot inline with name */}
                            {event.isTicketingLive ? (
                                <span className="inline-flex items-center text-xs text-stone-500 dark:text-stone-400">
                                    <span className="inline-block size-1.5 rounded-full bg-emerald-500 mr-1"></span>
                                    Live
                                </span>
                            ) : (
                                <span className="inline-flex items-center text-xs text-stone-500 dark:text-stone-400">
                                    <span className="inline-block size-1.5 rounded-full bg-stone-400 mr-1"></span>
                                    Not live
                                </span>
                            )}
                        </div>
                        <div className="text-sm text-stone-500 dark:text-stone-400">
                            {event.issued ? `${event.issued} sold` : 'No sales'}
                            {event.mostRecentTicketSold && (
                                <span>
                                    {' '}(last {formatDistanceToNow(event.mostRecentTicketSold.toDate(), { addSuffix: true })})
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div 
                className="flex items-center px-3 hover:bg-stone-100 dark:hover:bg-stone-800 cursor-pointer transition-colors duration-150 rounded-md mx-1"
                onClick={(e) => {
                    e.stopPropagation()
                    document.getElementById(`eventActions-${event.paymentLinkId}`).classList.toggle('hidden')
                }}
                data-dropdown={`eventActions-${event.paymentLinkId}`}
            >
                <EventMenu 
                    key={event.paymentLinkId}
                    eventId={event.paymentLinkId}
                    menuId={`eventActions-${event.paymentLinkId}`}
                    issued={event.issued || 0}
                    customTrigger={
                        <div className="text-stone-400 dark:text-stone-500 hover:text-stone-600 dark:hover:text-stone-300 transition-colors">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                        </div>
                    }
                    functions={functions}
                    events={events}
                    db={db}
                    chargesEnabled={chargesEnabled}
                    hasPaidTickets={event.ticketTypes && event.ticketTypes.some(type => type.price > 0)}
                />
            </div>
        </li>
    )
} 