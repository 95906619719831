import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import buyFlowImage from './images/buy_flow.png'
import buyFlowImageMobile from './images/buy_flow_mobile.png'
import wine from './images/wine.jpg'
import cozy from './images/cozy.jpg'
import beach from './images/beach.jpg'
import Footer from './components/Footer'

const features = [
    {
        name: 'Event pages',
        description: 'Add your logo, hero image, and accent color, while our brand stays out of the way.',
    },
    {
        name: 'Registration forms',
        description: 'Add a form to your page with multi-select, single select, and free form questions.'
    },
    {
        name: 'Vanity URLs',
        description: 'Get a tkt.page/your-event-name link to share that\'s easy on the eyes.'
    },
    {
        name: 'Embeddable',
        description: 'Embed your event page on your website for even more seamless registration.'
    },
    {
        name: 'Capacities',
        description: 'Set a maximum quantity available at either the ticket type level, event level, or both.',
    },
    {
        name: 'Pass on the fee',
        description: 'Our fee is $0.79 per ticket and can be passed to the buyer for cost-free ticketing.',
    },
    {
        name: 'Bundles',
        description: 'Sell any combo of tickets at a reduced price. Works seamlessly with capacities.',
    },
    {
        name: 'Free events',
        description: 'Simple event registration for free events. No fees whatsoever.',
    },
    {
        name: 'Ticketless option',
        description: 'Just need registration without issuing tickets? No problem.',
    },
    {
        name: 'Stripe checkout',
        description: 'Payments via Stripe Checkout, a secure and trusted payment processor.',
    },
    {
        name: 'Recurring Events',
        description: 'Create a repeating pattern for an event that occurs over multiple days and months.'
    },
    {
        name: 'Time Slots',
        description: 'Create up to 24 time slots in a single day for time based ticketing.'
    },
    {
        name: 'Blackout dates',
        description: 'Create custom availability for a single date that is unlike the rest.'
    },
    {
        name: 'Comp tickets',
        description: 'Send a free ticket to any email address right from the dashboard.',
    },
    {
        name: 'Discount codes',
        description: 'Allow coupon codes, or, send a link preloaded with a discount.',
    },
    {
        name: 'Rich event description',
        description: 'Use markdown and emojis to make your event description more engaging.',
    },
    {
        name: 'Ticket transfers',
        description: 'Ticket holders can share or fully transfer their ticket to another person.',
    },
    {
        name: 'Exchanges',
        description: 'Exchange tickets between types and events. Works seamlessly with capacities.',
    },
    {
        name: 'Refunds',
        description: 'Automatic ticket invalidation when the order is refunded in Stripe.',
    },
    {
        name: 'Add sales tax',
        description: 'Add a line item to display or pass on tax or fees.',
    },
    {
        name: 'Ticket scanning',
        description: 'Scan the QR codes on tickets with our easy to use Check-in app',
    },
    {
        name: 'Check-in by name',
        description: 'For a more personal feel at a smaller event, simply lookup by name and redeem.',
    },
    {
        name: 'PDF attendee list',
        description: 'Print one as a backup or as the primary method of check-in.',
    },
    {
        name: 'Apple & Google Wallet',
        description: 'The most convenient way for attendees to save their ticket.',
    },
    {
        name: 'Stripe app',
        description: 'Companion app for managing events from your Stripe Dashboard.',
    },
    {
        name: 'Quick support',
        description: 'Need something not on the list? Ask and we may be able to help!',
    },
]

const currencies = [
    {code: "USD", name: "United States Dollar", price:"$0.79"},
    {code: "CAD", name: "Canadian Dollar", price:"$1.10"},
    {code: "EUR", name: "Euro", price:"€0.79"},
    {code: "GBP", name: "British Pound", price:"£0.70"},
    {code: "AUD", name: "Australian Dollar", price:"$1.20"},
    {code: "NZD", name: "New Zealand Dollar", price:"$1.30"},
    {code: "DKK", name: "Danish Krone", price:"kr5.50"},
    {code: "INR", name: "Indian Rupee", price:"₹70"},
    {code: "RON", name: "Romanian Leu", price:"lei3.79"},
    {code: "CHF", name: "Swiss Franc", price:"CHF0.79"},
    {code: "ZAR", name: "South African Rand", price:"R18"},
    {code: "MXN", name: "Mexican Peso", price:"$15"},
    {code: "RUB", name: "Russian Ruble", price:"₽79"},
    {code: "BGN", name: "Bulgarian Lev", price:"лв1.50"},
    {code: "SGD", name: "Singapore Dollar", price:"$1.10"},
    {code: "SEK", name: "Swedish Krona", price:"kr9"},
    {code: "NOK", name: "Norwegian Krone", price:"kr9"},
    {code: "PLN", name: "Polish Zloty", price:"zł3.30"},
    {code: "AED", name: "Emirati Dirham", price:"3.00"},
]

export default function Homepage() {
    const [open, setOpen] = useState(false)

    return (
        <>
            <section className='py-20 lg:py-24'>
                <div className='w-10/12 max-w-4xl mx-auto'>
                    <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                    <h1 className="text-5xl tracking-tight font-bold sm:text-7xl text-stone-800 dark:text-stone-100 mx-auto">
                        Easy and affordable event registration
                    </h1>
                    <div className="text-stone-600 dark:text-stone-300 mt-6 text-lg leading-8">
                        <h2 className="mb-3 text-xl font-semibold">
                            See how it works with examples →
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                            <a 
                                href="https://tkt.page/comedy-night" 
                                target="_blank" 
                                rel="noreferrer"
                                className="relative group overflow-hidden rounded-lg transform transition-transform hover:scale-102 hover:shadow-lg"
                            >
                                <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/30 to-black/80 z-10"></div>
                                <div className="aspect-[16/9] md:aspect-[4/3] bg-cover bg-center" style={{ backgroundImage: `url(${cozy})` }}></div>
                                <div className="absolute bottom-0 left-0 right-0 p-4 text-white z-20">
                                    <h3 className="font-semibold text-lg">Comedy Night</h3>
                                    <p className="text-sm">Multiple paid ticket options</p>
                                </div>
                            </a>
                            <a 
                                href="https://tkt.page/winery-tour-tasting" 
                                target="_blank" 
                                rel="noreferrer"
                                className="relative group overflow-hidden rounded-lg transform transition-transform hover:scale-102 hover:shadow-lg"
                            >
                                <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/30 to-black/80 z-10"></div>
                                <div className="aspect-[16/9] md:aspect-[4/3] bg-cover bg-center" style={{ backgroundImage: `url(${wine})` }}></div>
                                <div className="absolute bottom-0 left-0 right-0 p-4 text-white z-20">
                                    <h3 className="font-semibold text-lg">Wine Tasting</h3>
                                    <p className="text-sm">Simple and free registration</p>
                                </div>
                            </a>
                            <a 
                                href="https://tkt.page/sea-side-yoga" 
                                target="_blank" 
                                rel="noreferrer"
                                className="relative group overflow-hidden rounded-lg transform transition-transform hover:scale-102 hover:shadow-lg"
                            >
                                <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/30 to-black/80 z-10"></div>
                                <div className="aspect-[16/9] md:aspect-[4/3] bg-cover bg-center" style={{ backgroundImage: `url(${beach})` }}></div>
                                <div className="absolute bottom-0 left-0 right-0 p-4 text-white z-20">
                                    <h3 className="font-semibold text-lg">Sea Side Yoga</h3>
                                    <p className="text-sm">Recurring time slots booking</p>
                                </div>
                            </a>
                        </div>
                        <div className="mt-8">
                        <h2 className="mb-3 text-xl font-semibold">
                            Create your first event in minutes →
                        </h2>
                            <a
                                href="/signin"
                                className="inline-flex items-center justify-center rounded-md bg-stone-800 dark:bg-stone-100 px-10 py-3 text-lg font-semibold text-stone-100 dark:text-stone-800 shadow-sm hover:bg-stone-700 dark:hover:bg-stone-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800 dark:focus-visible:outline-stone-100"
                            >
                                Get Started Now
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-12 sm:py-24 flex flex-col bg-teal-100 dark:bg-teal-950">
                <div className="mx-auto sm:text-center mb-6 px-6 lg:px-8">
                    <h2 className="text-pretty text-4xl font-semibold tracking-tight text-stone-800 dark:text-stone-100 sm:text-5xl max-w-2xl mx-auto">
                        Use our event page or embed it on your own website
                    </h2>
                    <p className="mt-2 text-lg/8 text-stone-600 dark:text-stone-300">
                        Need only ticketing or only registration? Either can be eliminated.
                    </p>
                </div>                
                <img
                    className="max-w-4xl mx-auto w-11/12 mt-12 hidden sm:block"
                    src={buyFlowImage}
                    alt="Attendee registration flow"
                />
                <img
                    className="max-w-4xl mx-auto w-11/12 mt-12 block sm:hidden"
                    src={buyFlowImageMobile}
                    alt="Attendee registration flow"
                />
            </section>
            <section>
                <div className="py-12 sm:py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto lg:mx-0 sm:text-center mb-12">
                            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-stone-800 dark:text-stone-100 sm:text-5xl">
                                Features
                            </h2>
                            <p className="mt-2 text-lg/8 text-stone-600 dark:text-stone-300">
                                Simple to use, fully capable ticketing and event registration platform
                            </p>
                        </div>
                            <dl className="grid grid-cols-1 gap-4 sm:gap-6 text-base leading-7 md:grid-cols-2 lg:grid-cols-3">
                                {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9 border border-stone-200 dark:border-stone-800 p-4 rounded-lg">
                                    <dt className="font-semibold">
                                    {feature.name}
                                    </dt>
                                    <dd className="mt-1 text-stone-600 dark:text-stone-300">{feature.description}</dd>
                                </div>
                                ))}
                            </dl>
                    </div>
                </div>
            </section>
            <section className="relative py-20 sm:py-28 px-6 bg-teal-100 dark:bg-teal-950">
                <div className="relative">
                    <div className="mx-auto max-w-lg text-center">
                        <p className='mb-2 text-lg'>Free events are free! Paid tickets are only...</p>
                        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-stone-800 dark:text-stone-100 sm:text-5xl">
                            $0.79 per ticket
                        </h2>
                        <p className="mt-2 text-small"><button className="underline cursor-pointer" onClick={() => setOpen(true)}>View in other currencies</button></p>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>
                                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-black p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">                                     
                                                <div className="text-center">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                                        Per ticket pricing
                                                    </Dialog.Title>
                                                </div>
                                                <ul className="divide-y divide-stone-100 dark:divide-stone-800 mt-2">
                                                    {currencies.map((currency) => (
                                                        <li key={currency.code} className="flex justify-between py-2">
                                                            <div className="flex gap-1 items-center">
                                                                <p className="text-sm font-semibold">{currency.code}</p>
                                                                <p className="truncate text-xs text-stone-600 dark:text-stone-300">{currency.name}</p>
                                                            </div>
                                                            <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                                                                <p className="text-sm">{currency.price}</p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p className='mt-2 text-xs text-stone-600 dark:text-stone-300'>Your currency missing? If you can use it in Stripe, you can use it in Sidebar Ticketing. You'll be charged at a reasonable conversion from USD.</p>
                                                <div className="mt-5 sm:mt-6">
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Dismiss
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        <p className="mt-6 text-lg max-w-lg text-center mx-auto">Pass this super low fee to the buyer<br />for cost-free ticketing!</p>
                        <div className="mt-6 text-lg max-w-lg text-center mx-auto">
                            <a
                                href="/signin"
                                className="inline-flex items-center rounded-md bg-teal-600 px-6 py-3 text-lg font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                                Sign up or Log in
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}