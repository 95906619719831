import { useFieldArray } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function TicketTypes({ 
    control, 
    register, 
    currency, 
    chargesEnabled, 
    errors,
    isSimpleMode,
    setIsSimpleMode
}) {

    const { fields, append, remove } = useFieldArray({
        control,
        name: "ticketTypes"
    })

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    return (
        <div className="space-y-4">
            <hr className="my-4 dark:border-stone-700" />
            {!chargesEnabled && (
                <p className="text-sm text-stone-500 dark:text-stone-400 mb-2">
                    <Link
                        to={`/settings`}
                        className="text-teal-600 hover:text-teal-800 hover:underline"
                    >
                        Connect Payments in Settings
                    </Link>
                    {" "}to enable paid events
                </p>
            )}
            <div className="space-y-2">
                <div className="flex items-center gap-x-3">
                    <input
                        type="radio"
                        checked={isSimpleMode}
                        className="h-4 w-4 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600"
                        onChange={() => {
                            if (window.confirm('Your existing ticket types will be deleted. Are you sure?')) {
                                setIsSimpleMode(true)
                                remove()
                                append({
                                    price: '',
                                    maxQty: 1,
                                    visible: true,
                                    capacity: '',
                                    description: '',
                                    key: Date.now().toString()
                                })
                            }
                        }}
                    />
                    <label>Simple registration</label>
                </div>
                <div className="flex items-center gap-x-3">
                    <input
                        type="radio"
                        checked={!isSimpleMode}
                        className="h-4 w-4 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600"
                        onChange={() => {
                            setIsSimpleMode(false)
                            remove()
                            append({
                                key: Date.now().toString(),
                                name: '',
                                price: '',
                                maxQty: 10,
                                visible: true,
                                capacity: '',
                                description: '',
                            })
                        }}
                    />
                    <label>Multiple options</label>
                </div>
            </div>
            {isSimpleMode &&
                <div className="space-y-4">
                    {fields.map((field, index) => (
                        <div key={field.id}>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div>
                                    <label>
                                        Price ({formatter.format(0).replace('0.00', '')})
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        step="1.00"
                                        disabled={!chargesEnabled}
                                        {...register(`ticketTypes.${index}.price`)}
                                        defaultValue={field.price}
                                        placeholder="free"
                                        className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.price ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 ${chargesEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'} bg-stone-50 dark:bg-stone-900`}
                                    />
                                </div>
                                <div>
                                    <label>Limit per order</label>
                                    <select
                                        {...register(`ticketTypes.${index}.maxQty`)}
                                        defaultValue={10}
                                        className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.maxQty ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                    >
                                        {[...Array(10)].map((_, i) => (
                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            {!isSimpleMode && (
                <div className="space-y-4">
                    <div className="space-y-4">
                        {fields.map((field, index) => (
                            <div key={field.id} className="border rounded-md p-4 bg-stone-50 dark:bg-stone-800 space-y-4 divide-y divide-stone-200 dark:divide-stone-700 dark:border-stone-700">
                                <div className="text-xs font-semibold uppercase">
                                    {field.bundle ? "Bundle" : "Ticket type"}
                                </div>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 pt-4">
                                    <div className="mt-1">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            {...register(`ticketTypes.${index}.name`)}
                                            className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.name ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-white dark:bg-stone-900`}
                                        />
                                        {errors?.ticketTypes?.[index]?.name && (
                                            <p className="mt-2 text-sm text-red-600">
                                                {errors.ticketTypes[index].name.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="mt-1">
                                        <label>Price ({formatter.format(0).replace('0.00', '')})</label>
                                        <input
                                            type="number"
                                            min="0"
                                            step="1.00"
                                            disabled={!chargesEnabled}
                                            {...register(`ticketTypes.${index}.price`)}
                                            defaultValue={field.price}
                                            placeholder="free"
                                            className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.price ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 ${chargesEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'} bg-white dark:bg-stone-900`}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>Limit per order</label>
                                        <select
                                            {...register(`ticketTypes.${index}.maxQty`)}
                                            defaultValue={10}
                                            className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.maxQty ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-white dark:bg-stone-900`}
                                        >
                                            {[...Array(10)].map((_, i) => (
                                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mt-1">
                                        <label>Capacity</label>
                                        <input
                                            type="number"
                                            placeholder="unlimited"
                                            min="0"
                                            {...register(`ticketTypes.${index}.capacity`)}
                                            className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.capacity ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-white dark:bg-stone-900`}
                                        />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label>Description</label>
                                        <textarea
                                            {...register(`ticketTypes.${index}.description`)}
                                            rows={2}
                                            className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.description ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-white dark:bg-stone-900`}
                                        />
                                        {errors?.ticketTypes?.[index]?.description && (
                                            <p className="mt-2 text-sm text-red-600">
                                                {errors.ticketTypes[index].description.message}
                                            </p>
                                        )}
                                    </div>
                                    {field.bundle && (
                                        <div className="sm:col-span-2 space-y-3">
                                            <label>Bundle Contents</label>
                                            <div className="space-y-2">
                                                {fields
                                                    .filter(f => !f.bundle && f.name)
                                                    .map(ticketType => (
                                                        <div key={ticketType.id} className="flex items-center gap-x-3">
                                                            <select
                                                                {...register(`ticketTypes.${index}.contents.${ticketType.id}`)}
                                                                className={`rounded-md border-0 py-1.5 ring-1 ring-inset ${errors?.ticketTypes?.[index]?.contents ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                                                defaultValue={0}
                                                            >
                                                                {[...Array(11)].map((_, i) => (
                                                                    <option key={i} value={i}>{i}</option>
                                                                ))}
                                                            </select>
                                                            <span className="text-sm text-stone-700 dark:text-stone-300">{ticketType.name}</span>
                                                        </div>
                                                    ))}
                                                {fields.filter(f => !f.bundle && f.name).length === 0 && (
                                                    <p className="text-sm text-stone-500 dark:text-stone-400">Add and name some ticket types first</p>
                                                )}
                                            </div>
                                            {errors?.ticketTypes?.[index]?.contents && (
                                                <p className="mt-2 text-sm text-red-600">
                                                    Bundle must include at least one ticket
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-between pt-4 h-12">
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            type="checkbox"
                                            {...register(`ticketTypes.${index}.visible`)}
                                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                                        />
                                        <label>
                                            Visible to buyers
                                        </label>
                                    </div>
                                    {fields.length > 1 &&
                                        <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="inline-flex items-center rounded-md bg-white dark:bg-stone-900 px-3 py-1 font-medium text-stone-900 dark:text-stone-100 ring-1 ring-inset ring-stone-300 dark:ring-stone-600 hover:bg-stone-50 dark:hover:bg-stone-800"
                                        >
                                            Delete
                                        </button>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex gap-x-3">
                        <button
                            type="button"
                            onClick={() => append({
                                key: Date.now().toString(),
                                name: '',
                                price: '',
                                maxQty: 10,
                                visible: true,
                                capacity: '',
                                description: '',
                                bundle: false
                            })}
                            className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 flex items-center gap-2"
                        >
                            <PlusIcon className="h-4 w-4" />
                            Add ticket type
                        </button>
                        {fields.length > 0 &&
                            <button
                                type="button"
                                onClick={() => append({
                                    name: '',
                                    description: '',
                                    price: '',
                                    maxQty: 10,
                                    key: Date.now().toString(),
                                    bundle: true,
                                    contents: [],
                                    visible: true,
                                    capacity: ''
                                })}
                                className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 flex items-center gap-2"
                            >
                                <PlusIcon className="h-4 w-4" />
                                Create a bundle
                            </button>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}