import { useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { sendLoginLink } from './auth/AuthService'
import Footer from './components/Footer'
import { getUtmParams } from './utils/utmParams'

export default function SwitchFromEventbrite({ functions }) {
    const [email, setEmail] = useState('');
    const [eventbriteLink, setEventbriteLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setError(null)
        const promises = []
        try {
            const addNewLead = httpsCallable(functions, 'addNewLead')
            const utmParams = getUtmParams()
            
            promises.push(addNewLead({
                email: email,
                website: eventbriteLink,
                source: 'switch-from-eventbrite',
                ...utmParams
            }))
            promises.push(sendLoginLink(email))
            await Promise.all(promises)
            setIsSubmitted(true)
            
            const { utm_source, utm_medium } = utmParams
            if (window.gtag_report_conversion && utm_source === 'google' && utm_medium === 'cpc') {
                window.gtag_report_conversion()
            }
        } catch (err) {
            console.error('Error from addNewLead:', err)
            setError(err.message || 'Something went wrong')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <section className='pt-20 lg:pt-24'>
                <div className='w-10/12 max-w-3xl mx-auto'>
                    <img className="h-9 lg:h-12 mb-12 sidebar-logo" alt="sidebar-ticketing-logo" />
                    <h1 className="text-[2.5rem] font-thin leading-[1.2] sm:leading-[1.4]">
                        <span className="font-bold text-teal-600 dark:text-teal-400">Here's the thing.</span> Eventbrite is a public company now. To find growth, they are driving up prices and finding other ways to extract value from your events.
                    </h1>
                    <h1 className="text-[2.5rem] font-thin mt-8 leading-[1.2] sm:leading-[1.4]">
                    <span className="font-bold text-teal-600 dark:text-teal-400">Try switching to Sidebar Ticketing.</span> We probably have all the features you need, are listening to your feedback, and charge only $0.79 per ticket.
                    </h1>
                    <h1 className="text-[2.5rem] font-thin mt-8 leading-[1.2] sm:leading-[1.4]">
                    <span className="font-bold text-teal-600 dark:text-teal-400">Enter your email to get started.</span> We will never share it or use it for anything other than telling you about Sidebar.
                    </h1>
                </div>
            </section>

            <section>
                <div className='w-11/12 max-w-4xl mx-auto my-16 flex justify-center'>
                    <div className="w-full max-w-lg border border-stone-200 dark:border-stone-700 rounded-lg p-6 shadow-sm bg-white dark:bg-stone-900/60">
                        {isSubmitted ? (
                            <div className="text-center">
                                <h2 className="text-3xl font-bold text-stone-800 dark:text-stone-100">Thanks! Check your email</h2>
                                <p className="mt-4 text-stone-600 dark:text-stone-400">Then click your sign-in link</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-2"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-4 py-3 bg-white dark:bg-stone-900 border border-stone-300 dark:border-stone-700 rounded-lg focus:border-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-500 transition duration-150 ease-in-out"
                                        placeholder="your@email.com"
                                        required
                                    />
                                </div>

                                <div>
                                    <label
                                        htmlFor="eventbriteLink"
                                        className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-2"
                                    >
                                        Event website or Eventbrite link (optional)
                                    </label>
                                    <input
                                        type="url"
                                        id="eventbriteLink"
                                        value={eventbriteLink}
                                        onChange={(e) => setEventbriteLink(e.target.value)}
                                        className="w-full px-4 py-3 bg-white dark:bg-stone-900 border border-stone-300 dark:border-stone-700 rounded-lg focus:border-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-500 transition duration-150 ease-in-out"
                                        placeholder="https://www.eventbrite.com/e/your-event"
                                    />
                                    <p className="mt-1 text-sm text-stone-500 dark:text-stone-400">
                                        We'll use this to create an example event for you
                                    </p>
                                </div>

                                {error && (
                                    <div className="p-4 bg-red-50 border border-red-100 text-red-700 rounded-lg flex items-center">
                                        <svg className="h-5 w-5 text-red-400 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
                                        </svg>
                                        {error}
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`w-full py-3 px-4 rounded-lg text-white bg-teal-600 hover:bg-teal-500 font-semibold 
                                    transition duration-150 ease-in-out transform hover:scale-[1.02] 
                                    focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2
                                    ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <span className="flex items-center justify-center">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Sending...
                                        </span>
                                    ) : 'Get Started'}
                                </button>

                            </form>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}