export default function Options({ register, chargesEnabled, watch }) {

    const registrationMode = watch('registrationMode')
    
    return (
        <div className="space-y-2 pt-4">
            <div className="flex items-center gap-x-3">
                <input
                    type="checkbox"
                    checked={watch('eventCheckinType') === 'tickets'}
                    onChange={(e) => {
                        register('eventCheckinType').onChange({
                            target: {
                                name: 'eventCheckinType',
                                value: e.target.checked ? 'tickets' : 'name'
                            }
                        })
                    }}
                    className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                />
                <label>Issue tickets</label>
            </div>
            {chargesEnabled && registrationMode !== 'free' && (
                <>
                    <div className="flex items-center gap-x-3">
                        <input
                            type="checkbox"
                            {...register('buyerPaysFees')}
                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                        />
                        <label>Pass ticketing fees to buyer</label>
                    </div>
                    
                    <div className="flex items-center gap-x-3">
                        <input
                            type="checkbox"
                            {...register('allowPromoCodes')}
                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                        />
                        <label>Allow coupon codes</label>
                    </div>

                    <div className="flex items-center gap-x-3">
                        <input
                            type="checkbox"
                            {...register('collectPhoneNumber')}
                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                        />
                        <label>Require buyer phone number</label>
                    </div>

                    <div className="flex items-center gap-x-3">
                        <input
                            type="checkbox"
                            {...register('collectAddress')}
                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                        />
                        <label>Require buyer address</label>
                    </div>
                </>
            )}
        </div>
    )
}