/* eslint-disable jsx-a11y/no-redundant-roles */
import EventCard from '../components/EventCard'
import { useState, useEffect } from 'react'
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { PlusIcon } from '@heroicons/react/24/outline'
export default function Events({events, db, uid, functions, userData}) {
    const [showArchived, setShowArchived] = useState(false)
    const [archivedEvents, setArchivedEvents] = useState([])
    const [isStripeLoading, setIsStripeLoading] = useState(false)

    // Check if the user needs to complete Stripe requirements
    const hasStripeRequirements = userData?.stripeConnectHasCurrentlyDue === true

    const handleStripeConnect = async () => {
        try {
            setIsStripeLoading(true)
            const createStripeHostedOnboardingLink = httpsCallable(functions, 'createStripeHostedOnboardingLink')
            const { data } = await createStripeHostedOnboardingLink({
                stripeAccountId: userData?.stripeAccountId
            })
            
            if (data.url) {
                window.location.href = data.url
                return
            }
            setIsStripeLoading(false)
        } catch (error) {
            console.error('Error creating Stripe account link:', error)
            setIsStripeLoading(false)
        }
    }

    // Only fetch archived events when needed
    useEffect(() => {
        if (!showArchived) return

        const q = query(collection(db, "events"),
            where("accountId", "==", uid),
            where("archived", "==", true),
            orderBy("lastModified", "desc"),
            limit(250)
        )

        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                setArchivedEvents(querySnapshot.docs.map(doc => ({
                    paymentLinkId: doc.id,
                    ...doc.data()
                })))
            },
            (error) => {
                console.log(error)
            }
        )

        return () => unsubscribe()
    }, [db, uid, showArchived])

    const displayedEvents = (showArchived ? archivedEvents : events)

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
                <h1 className="text-3xl font-bold">{showArchived ? 'Archived' : 'Events'}</h1>
                <div className="flex items-center gap-x-2">
                    <button
                        onClick={() => setShowArchived(!showArchived)}
                        className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
                    >
                        {showArchived ? 'Back to active events' : 'Show archived'}
                    </button>
                    <Link
                        to="/events/new"
                        className="inline-flex items-center gap-1 text-white bg-teal-600 hover:bg-teal-700 px-2 py-1 rounded-md border border-teal-600"
                    >
                        <PlusIcon className="h-3.5 w-3.5" />
                        Create event
                    </Link>
                </div>
            </div>
            {/* Stripe Requirements Banner */}
            {hasStripeRequirements && (
                <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4 mb-4">
                    <div className="flex items-start sm:items-center justify-between flex-wrap gap-2 flex-col sm:flex-row">
                        <div className="flex-1">
                            <h3 className="font-medium text-yellow-800 dark:text-yellow-200">
                                Action required for your payment account
                            </h3>
                            <p className="text-yellow-700 dark:text-yellow-300 text-sm">
                                To continue receiving payments, you need to complete your Stripe account setup.
                            </p>
                        </div>
                        <button
                            onClick={handleStripeConnect}
                            disabled={isStripeLoading}
                            className="inline-flex items-center px-4 py-2 rounded-lg text-white bg-yellow-600 hover:bg-yellow-700
                                transition duration-150 ease-in-out
                                focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2
                                disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isStripeLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </>
                            ) : (
                                'Complete Account Setup'
                            )}
                        </button>
                    </div>
                </div>
            )}
            <div className="relative">
                <ul role="list">
                    {displayedEvents.map((event) => (
                        <EventCard 
                            key={event.id}
                            event={event}
                            functions={functions}
                            events={events}
                            db={db}
                            chargesEnabled={userData?.stripeConnectChargesEnabled || false}
                        />
                    ))}
                </ul>
            </div>
        </div>
    )
}
  