export default function Footer() {
    return (
        <footer className="pb-20">
            <div className="max-w-7xl mx-auto pt-8 flex flex-col items-center" aria-label="Footer">
                <div className='flex gap-4 flex-row items-center mt-4'>
                    <a className='text-stone-600 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-400 underline' href='/'>Home</a>
                    <a className='text-stone-600 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-400 underline' href='/terms'>Terms</a>
                    <a className='text-stone-600 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-400 underline' href='/privacy'>Privacy</a>
                    <a className='text-stone-600 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-400 underline' href='/pricing'>Pricing</a>
                    <a className='text-stone-600 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-400 underline' href='/switch-from-eventbrite'>Switch</a>
                </div>
                <div className="mt-6 text-center text-sm text-stone-500 dark:text-stone-400 px-6">
                    &copy; Sidebar Ticketing, LLC. Built by a team that's been in online ticketing since 2009. Say hi at <a href="mailto:hello@sidebarticketing.com" className="text-teal-600 dark:text-teal-400 hover:underline">hello@sidebarticketing.com</a>.
                </div>
            </div>
        </footer>
    )
}