import { useState, useEffect, useRef } from 'react'
import addToAppleWalletButton from './images/Add_to_Apple_Wallet.svg'
import addToGoogleWalletButton from './images/enUS_add_to_google_wallet_add-wallet-badge.svg'
import AccountIcon from './AccountIcon'
import { httpsCallable } from "firebase/functions"

export default function Ticket({functions}) {
    const [ticketName, setTicketName] = useState("")
    const [ticketId, setTicketId] = useState("")
    const [ticketCode, setTicketCode] = useState("")
    const [eventName, setEventName] = useState("")
    const [locationName, setLocationName] = useState("")
    const [fullAddress, setFullAddress] = useState("")
    const [dateString, setDateString] = useState("")
    const [accountDisplayName, setAccountDisplayName] = useState("")
    const [accountId, setAccountId] = useState("")
    const [primaryColor, setPrimaryColor] = useState("")
    const [isFetchingPass, setIsFetchingPass] = useState(false)
    const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null)
    const [showTransferForm, setShowTransferForm] = useState(false)
    const [recipientEmail, setRecipientEmail] = useState("")
    const [recipientName, setRecipientName] = useState("")
    const [shouldInvalidateBarcode, setShouldInvalidateBarcode] = useState(false)
    const [isTransferring, setIsTransferring] = useState(false)
    const [transferError, setTransferError] = useState("")
    const [transferSuccess, setTransferSuccess] = useState(false)
    
    // Add ref for email input auto-focus
    const emailInputRef = useRef(null)
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const encodedString = urlParams.get('t')
        function b64_to_utf8(str) {
            return decodeURIComponent(escape(window.atob(str)))
        }
        const decodedString = b64_to_utf8(encodedString)
        const ticket = JSON.parse(decodedString)
        var QRCode = require('qrcode')
        QRCode.toDataURL(ticket.ticketId).then(code => {
            setTicketName(ticket.ticketName)
            setTicketId(ticket.ticketId)
            setTicketCode(code)
            setDateString(ticket.dateString.trim())
            setEventName(ticket.eventName)
            setLocationName(ticket.locationName)
            setFullAddress(ticket.fullAddress)
            setAccountDisplayName(ticket.accountDisplayName)
            setAccountId(ticket.accountId)
            setPrimaryColor(ticket.primaryColor || "#f1d087")
            setLat(ticket.locationCoordinates.lat)
            setLng(ticket.locationCoordinates.lng)
        })
    },[])

    // Add a useEffect to focus the email input when form appears
    useEffect(() => {
        if (showTransferForm && emailInputRef.current) {
            emailInputRef.current.focus()
        }
    }, [showTransferForm])

    function getContrastYIQ(hexcolor){
        hexcolor = hexcolor.replace("#", "")
        var r = parseInt(hexcolor.substr(0,2),16)
        var g = parseInt(hexcolor.substr(2,2),16)
        var b = parseInt(hexcolor.substr(4,2),16)
        var yiq = ((r*299)+(g*587)+(b*114))/1000
        return (yiq >= 128) ? '#000000' : '#ffffff'
    }

    const downloadAppleWalletPass = async () => {
        try {
            setIsFetchingPass(true)
            const locationString = `${locationName ? `${locationName}, ` : ""}${fullAddress}`
            const requestBody = {
                ticket_id: ticketId,
                account_name: accountDisplayName,
                color: primaryColor,
                ...(dateString && { date_string: dateString }),
                ...(locationString && { location_string: locationString }),
                ...(lat && { lat: lat }),
                ...(lng && { lng: lng }),
                ...(locationName && { locationName: locationName }),
            }
            const response = await fetch(`https://api.sidebarticketing.com/f/download-apple-wallet-pass`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
            setIsFetchingPass(false)
            const blob = await response.blob()
            const blobUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = blobUrl
            a.download = `${eventName}_ticket.pkpass`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(blobUrl)
            a.remove()
        } catch (error) {
            console.log(error)
        }
    }

    const downloadGoogleWalletPass = async () => {
        try {
            setIsFetchingPass(true)
            const locationString = `${locationName ? `${locationName}, ` : ""}${fullAddress}`
            const requestBody = {
                ticket_id: ticketId,
                account_name: accountDisplayName,
                color: primaryColor,
                ...(dateString && { date_string: dateString }),
                ...(locationString && { location_string: locationString }),
                ...(lat && { lat: lat }),
                ...(lng && { lng: lng }),
                ...(locationName && { locationName: locationName }),
            }
            const response = await fetch(`https://api.sidebarticketing.com/f/download-google-wallet-pass`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
            setIsFetchingPass(false)
            const url = await response.text()
            window.location.href = url
        } catch (error) {
            console.log(error)
        }
    }

    const handleTransferTicket = async () => {
        if (!recipientEmail) {
            setTransferError("Please enter a recipient email address")
            return
        }

        try {
            setIsTransferring(true)
            setTransferError("")
            
            const transferTicketFn = httpsCallable(functions, 'transferTicket')
            
            await transferTicketFn({
                ticketId,
                recipientEmailAddress: recipientEmail,
                recipientName,
                shouldInvalidateBarcode
            })
            
            setIsTransferring(false)
            setShowTransferForm(false)
            setRecipientEmail("")
            setRecipientName("")
            setTransferSuccess(true)
            
            // If the barcode was invalidated, we should refresh the QR code
            if (shouldInvalidateBarcode) {
                // We could redirect or show a message that the ticket is no longer valid
                // For now we'll just show a success message
            }
        } catch (error) {
            setIsTransferring(false)
            setTransferError(error.message || "Failed to transfer ticket. Please try again.")
            console.error("Transfer error:", error)
        }
    }

    return (
        <div className="flex flex-col items-center p-4">
            <div style={{ backgroundColor: primaryColor, color: getContrastYIQ(primaryColor)}} className='mb-6 md:my-12 w-full max-w-md drop-shadow-xl px-8 py-6 rounded-lg border border-neutral-200'>
                <div className='flex flex-row items-center'>
                    <AccountIcon accountId={accountId} size={150} />
                    <p>{accountDisplayName}</p>
                </div>
                <p className="text-3xl font-bold mt-6">{ticketName}</p>
                <p>{eventName}</p>
                <div className="mt-8 flex flex-col">
                    {locationName && <p>{locationName}</p>}
                    {fullAddress && <p>{fullAddress}</p>}
                    {dateString && <p>{dateString}</p>}
                </div>
                <div className="mt-8 flex flex-col items-center rounded-lg gap-2">
                    <img src={ticketCode} alt="QR Code" className="h-40 w-40 flex-none rounded-lg bg-white object-cover object-center " />
                    <p className="text-sm font-light">{ticketId}</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                {!isFetchingPass ?
                    <>
                        <button disabled={isFetchingPass} onClick={downloadAppleWalletPass}>
                            <img className='h-14 pb-4 box-content' src={addToAppleWalletButton} alt="Add to Apple Wallet" />
                        </button>
                        <button disabled={isFetchingPass} onClick={downloadGoogleWalletPass}>
                            <img className='h-14 pb-4 box-content' src={addToGoogleWalletButton} alt="Add to Apple Wallet" />
                        </button>
                    </>
                :
                    <div className='h-14 pb-4 box-content animate-pulse'> Generating Wallet Pass...</div>
                }
            </div>
            
            {/* Transfer Ticket Button and Form */}
            <div className="w-full max-w-md mt-2 mb-6 flex flex-col items-center">
                {!showTransferForm && !transferSuccess ? (
                    <button 
                        onClick={() => setShowTransferForm(true)}
                        className="text-neutral-600 hover:text-neutral-800 text-sm font-medium underline"
                    >
                        Transfer this ticket
                    </button>
                ) : transferSuccess ? (
                    <div className="text-green-600 text-sm">
                        Ticket successfully transferred
                    </div>
                ) : null}
                
                {showTransferForm && (
                    <div className="mt-4 p-4 border border-neutral-200 rounded-lg w-full">
                        <h3 className="text-lg font-medium mb-2">Transfer Ticket</h3>                        
                        <div className="mb-4">
                            <label htmlFor="recipientEmail" className="block text-sm font-medium text-neutral-700 mb-1">
                                Email Address
                            </label>
                            <input
                                ref={emailInputRef}
                                type="email"
                                id="recipientEmail"
                                value={recipientEmail}
                                onChange={(e) => setRecipientEmail(e.target.value)}
                                className="w-full px-3 py-2 border border-neutral-300 rounded-md"
                                placeholder="email@example.com"
                                disabled={isTransferring}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="recipientName" className="block text-sm font-medium text-neutral-700 mb-1">
                                Name
                            </label>
                            <input
                                type="text"
                                id="recipientName"
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                                className="w-full px-3 py-2 border border-neutral-300 rounded-md"
                                placeholder="Full Name"
                                disabled={isTransferring}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={shouldInvalidateBarcode}
                                    onChange={(e) => setShouldInvalidateBarcode(e.target.checked)}
                                    className="h-4 w-4 text-blue-600 border-neutral-300 rounded mr-2"
                                    disabled={isTransferring}
                                />
                                <span className="text-sm text-neutral-700">Invalidate my ticket after transfer</span>
                            </label>
                            <p className="text-xs text-neutral-500 mt-1">
                                Check this if you're selling the ticket. Leave unchecked if you're just sharing with a member of your party and would like your copy of the ticket to remain valid as a backup.
                            </p>
                        </div>
                        
                        {transferError && (
                            <div className="mb-4 text-sm text-red-600">
                                {transferError}
                            </div>
                        )}
                        
                        <div className="flex justify-end gap-2">
                            <button
                                type="button"
                                onClick={() => setShowTransferForm(false)}
                                className="px-3 py-1.5 text-sm font-medium text-neutral-700 border border-neutral-300 rounded-md hover:bg-neutral-50"
                                disabled={isTransferring}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleTransferTicket}
                                className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                disabled={isTransferring}
                            >
                                {isTransferring ? 'Transferring...' : 'Transfer'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            
            <footer>
                <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                    <p className="text-center text-xs leading-5 text-neutral-500 dark:text-neutral-400">
                        &copy; Sidebar Ticketing, LLC. All rights reserved. <br /> <a href="https://sidebarticketing.com" alt="sidebar ticketing" className='underline'>Sell tickets with Stripe</a>
                    </p>
                </div>
            </footer>
        </div>
    )
}