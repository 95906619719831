import { useEffect } from 'react'
import Options from './Options'
import PlacesAPISearch from './PlacesAPISearch'

export default function OptionalDetails({ register, errors, chargesEnabled, watch, setValue }) {
    // Register hidden fields for all location data
    useEffect(() => {
        // Register location related fields that aren't directly tied to inputs
        register('eventLocationName')
        register('eventFullAddress')
        register('eventLocationCoordinates')
        register('eventPlaceId')
    }, [register])

    const handlePlaceSelect = (placeDetails) => {
        // Make sure we handle coordinates properly (they must be a plain object for Firebase)
        const coordinates = placeDetails.coordinates ? {
            lat: placeDetails.coordinates.lat,
            lng: placeDetails.coordinates.lng
        } : null
        
        // Update form values using setValue from React Hook Form
        setValue('eventLocationName', placeDetails.locationName || '')
        setValue('eventFullAddress', placeDetails.fullAddress || '')
        setValue('eventLocationCoordinates', coordinates)
        setValue('eventPlaceId', placeDetails.placeId || '')
    }

    return (
        <div className="space-y-2">
            <hr className="my-4 dark:border-stone-700" />
            <div>
                <label>Event capacity</label>
                <div className="mt-1">
                    <input
                        type="number"
                        placeholder="unlimited"
                        min="0"
                        {...register('capacity')}
                        className="block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ring-stone-300 dark:ring-stone-700 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900"
                    />
                </div>
            </div>
            <div>
                <label>Event location</label>
                <div className="mt-1">
                    <PlacesAPISearch 
                        onSelect={handlePlaceSelect} 
                        defaultValue={watch('eventFullAddress') || ''}
                        initialLocationName={watch('eventLocationName') || ''}
                        setValue={setValue}
                    />
                </div>
                {errors.eventLocationName && (
                    <p className="mt-2 text-sm text-red-600">
                        {errors.eventLocationName.message}
                    </p>
                )}
            </div>

            <div className="sm:col-span-2">
                <label>Post purchase memo / instructions</label>
                <div className="mt-1">
                    <textarea
                        rows={4}
                        {...register('eventInstructions')}
                        className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventInstructions ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-700'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                    />
                    {errors.eventInstructions && (
                        <p className="mt-2 text-sm text-red-600">
                            {errors.eventInstructions.message}
                        </p>
                    )}
                </div>
            </div>
            <Options
                register={register}
                errors={errors}
                chargesEnabled={chargesEnabled}
                watch={watch}
            />
        </div>
    )
}