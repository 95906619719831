import { Fragment } from 'react'
import { TicketIcon, ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import { Link } from 'react-router-dom'
import DownloadAttendees from './DownloadAttendees'

export default function AttendeesMenu({ 
    auth, 
    uid, 
    eventId, 
    issued,
    isDownloading,
    setIsDownloading,
    downloadType,
    setDownloadType 
}) {
    return (
        <>
            <Menu as="div" className="relative">
                {({ open, close }) => (
                    <>
                        <MenuButton 
                            className="inline-flex items-center gap-1 text-white bg-teal-600 hover:bg-teal-700 px-2 py-1 rounded-md border border-teal-600"
                            disabled={isDownloading}
                        >
                            {isDownloading ? (
                                <ArrowDownTrayIcon className="h-3.5 w-3.5 animate-bounce" />
                            ) : (
                                <TicketIcon className="h-3.5 w-3.5" />
                            )}
                            <span>{isDownloading ? `Downloading ${downloadType}...` : "Attendees"}</span>
                            {!isDownloading && <ChevronDownIcon className="h-3.5 w-3.5" />}
                        </MenuButton>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white dark:bg-stone-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-stone-100 dark:divide-stone-700">
                                <div className="py-1">
                                    <MenuItem>
                                        {({ focus }) => (
                                            <Link
                                                to={`/events/${eventId}/registrations`}
                                                className={`${
                                                    focus ? 'bg-stone-100 dark:bg-stone-700' : ''
                                                } flex items-center px-4 py-2 text-stone-700 dark:text-stone-300 w-full text-left cursor-pointer`}
                                            >
                                                <TicketIcon className="h-5 w-5 mr-3" />
                                                Check-in List
                                            </Link>
                                        )}
                                    </MenuItem>
                                </div>
                                <div className="py-1">
                                    <DownloadAttendees 
                                        auth={auth}
                                        uid={uid}
                                        eventId={eventId}
                                        variant="menuItem"
                                        issued={issued}
                                        setIsDownloading={setIsDownloading}
                                        setDownloadType={setDownloadType}
                                        closeMenu={close}
                                    />
                                </div>
                            </MenuItems>
                        </Transition>
                    </>
                )}
            </Menu>
        </>
    )
} 