import { useState, useEffect } from 'react'
import { httpsCallable } from 'firebase/functions'
import UserAccountIcon from '../components/userAccountIcon'
import { signOut } from 'firebase/auth'
import { useSearchParams } from 'react-router-dom'
import { initiateEmailUpdate, sendReauthenticationLink, completeReauth } from '../auth/AuthService'
import { doc, updateDoc, deleteField } from 'firebase/firestore'
import { XMarkIcon, EnvelopeIcon, CreditCardIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline'
import ReauthDialog from '../components/ReauthDialog'
import { getStorage, ref, uploadBytes } from "firebase/storage"
import pica from 'pica'
import AuthorizedUsersManager from '../components/AuthorizedUsersManager'
import { Dialog } from '@headlessui/react'

export default function Settings({ uid, userData, functions, auth, db, isMainAccountUser }) {
    const [isRevoking, setIsRevoking] = useState(false)
    const [showSignOutDialog, setShowSignOutDialog] = useState(false)
    const [revokeStatusMessage, setRevokeStatusMessage] = useState('')
    const [hasEditedTaxSettings, setHasEditedTaxSettings] = useState(false)
    const [taxDescription, setTaxDescription] = useState("")
    const [taxPercentage, setTaxPercentage] = useState("")
    const [taxIsAddedOn, setTaxIsAddedOn] = useState()
    const [isTaxSaveLoading, setIsTaxSaveLoading] = useState(false)
    const [taxSaveMessage, setTaxSaveMessage] = useState("")
    const [isLoadingQr, setIsLoadingQr] = useState(false)
    const [emailChangeMessage, setEmailChangeMessage] = useState('')
    const [searchParams] = useSearchParams()
    const [showReauth, setShowReauth] = useState(false)
    const [dialogMode, setDialogMode] = useState('request')
    const [isStripeLoading, setIsStripeLoading] = useState(false)

    // New state variables for editable details
    const [editableDisplayName, setEditableDisplayName] = useState("")
    const [editableSupportEmail, setEditableSupportEmail] = useState("")
    const [editablePrimaryColor, setEditablePrimaryColor] = useState("#000000")
    const [editableSecondaryColor, setEditableSecondaryColor] = useState("#000000")
    const [isDetailsSaveLoading, setIsDetailsSaveLoading] = useState(false)
    const [detailsSaveMessage, setDetailsSaveMessage] = useState("")
    const [hasEditedDetails, setHasEditedDetails] = useState(false)

    // Determine if user needs to complete Stripe onboarding
    // For connectPlatform=2 users who either have stripeConnectHasCurrentlyDue=true or the field doesn't exist
    const needsStripeOnboarding = userData?.connectPlatform === 2 && (
        userData?.stripeConnectHasCurrentlyDue === true || 
        userData?.stripeConnectHasCurrentlyDue === undefined ||
        userData?.stripeConnectHasEventuallyDue === true ||
        userData?.stripeConnectHasPastDue === true
    )
    
    // Determine if the user is connected to Stripe
    const isConnectedToStripe = userData?.stripeConnectChargesEnabled === true

    useEffect(() => {
        if (userData) {
            setTaxDescription(userData.salesTaxDescription || "")
            setTaxPercentage(userData.salesTaxPercentage || "")
            setTaxIsAddedOn(userData.salesTaxIsAddedOn)
            
            // Initialize editable fields from userData
            setEditableDisplayName(userData.displayName || "")
            setEditableSupportEmail(userData.supportEmailAddress || userData.emailAddress || "")
            setEditablePrimaryColor(userData.primaryColor || "#000000")
            setEditableSecondaryColor(userData.secondaryColor || "#000000")
        }
    }, [userData])

    useEffect(() => {
        const mode = searchParams.get('mode')
        if (mode === 'signIn') {
            completeReauth()
                .then(() => {
                    setShowReauth(true)
                    setDialogMode('update')
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.delete('mode')
                    window.history.replaceState({}, '', `${window.location.pathname}?${newSearchParams}`)
                })
                .catch(error => {
                    console.error('Error completing reauth:', error)
                    setEmailChangeMessage(error.message)
                })
        }
    }, [searchParams])

    const handleTaxPercentageChange = (value) => {
        setTaxPercentage(value)
        setHasEditedTaxSettings(true)
    }

    const handleTaxDescriptionChange = (value) => {
        setTaxDescription(value)
        setHasEditedTaxSettings(true)
    }

    const handleTaxAddOnStatusChange = (value) => {
        setTaxIsAddedOn(value)
        setHasEditedTaxSettings(true)
    }

    // Handle changes to editable details fields
    const handleDetailsChange = () => {
        setHasEditedDetails(true)
        setDetailsSaveMessage("")
    }

    const saveUserDetails = async () => {
        if (!editableDisplayName || editableDisplayName.trim().length < 2) {
            setDetailsSaveMessage("Name is required (minimum 2 characters)")
            return
        }
        
        if (!editableSupportEmail || !editableSupportEmail.includes('@')) {
            setDetailsSaveMessage("Valid support email is required")
            return
        }
        
        setIsDetailsSaveLoading(true)
        try {
            const userRef = doc(db, 'users', uid)
            await updateDoc(userRef, {
                displayName: editableDisplayName.trim(),
                supportEmailAddress: editableSupportEmail.trim(),
                primaryColor: editablePrimaryColor,
                secondaryColor: editableSecondaryColor
            })
            setDetailsSaveMessage("Changes saved")
            setHasEditedDetails(false)
        } catch (error) {
            console.error('Error saving user details:', error)
            setDetailsSaveMessage("There was an error saving your changes")
        } finally {
            setIsDetailsSaveLoading(false)
        }
    }

    const saveTaxSettings = async () => {
        if (taxPercentage && (!Number(taxPercentage) || Number(taxPercentage) < 1)) {
            setTaxSaveMessage("If set, percentage must be a valid number above 1")
            setIsTaxSaveLoading(false)
            return
        }
        if (taxDescription && taxDescription.length > 50) {
            setTaxSaveMessage("Description is limited to 50 characters")
            setIsTaxSaveLoading(false)
            return
        }
        if (taxDescription && taxDescription.length < 3) {
            setTaxSaveMessage("If set, description must be at least 3 characters")
            setIsTaxSaveLoading(false)
            return
        }
        
        setIsTaxSaveLoading(true)
        try {
            const userRef = doc(db, 'users', uid)
            const updates = {}

            if (taxPercentage) {
                updates.salesTaxPercentage = Number(taxPercentage)
            } else {
                updates.salesTaxPercentage = deleteField()
            }

            if (taxDescription) {
                updates.salesTaxDescription = taxDescription
            } else {
                updates.salesTaxDescription = deleteField()
            }

            if (taxIsAddedOn !== undefined) {
                updates.salesTaxIsAddedOn = taxIsAddedOn
            } else {
                updates.salesTaxIsAddedOn = deleteField()
            }

            await updateDoc(userRef, updates)
            setTaxSaveMessage("Changes saved")
        } catch (error) {
            console.error('Error saving tax settings:', error)
            setTaxSaveMessage("There was an error")
        } finally {
            setIsTaxSaveLoading(false)
        }
    }

    const revoke = async () => {
        setIsRevoking(true)
        try {
            const revokeTokens = httpsCallable(functions, 'revokeTokens')
            await revokeTokens()
            await signOut(auth)
            setRevokeStatusMessage("All sessions signed out")
        } catch (error) {
            console.error('Error revoking sessions:', error)
            setRevokeStatusMessage("Error signing out of all sessions")
        } finally {
            setIsRevoking(false)
        }
    }

    async function generateLoginCode () {
        try {
            setIsLoadingQr(true)
            const getLoginToken = httpsCallable(functions,'getLoginToken')
            const result = await getLoginToken()
            if (result.data !== "error") {
                const encodedString = window.btoa(encodeURIComponent(result.data))
                const url = `${window.location.origin}?t=${encodedString}`
                await navigator.clipboard.writeText(url)
                alert("Link copied to clipboard. Share with caution as it will give immediate access to your account. The link will expire in 1 hour.")
            }
            setIsLoadingQr(false)
        } catch (error) {
            setIsLoadingQr(false)
            console.log(error)
        }
    }

    const changeLoginEmail = async () => {
        const mode = searchParams.get('mode')
        if (mode === 'signIn') {
            setDialogMode('update')
        } else {
            setDialogMode('request')
        }
        setShowReauth(true)
    }

    const handleReauthComplete = async (email) => {
        if (dialogMode === 'request') {
            await sendReauthenticationLink()
            setEmailChangeMessage('Check your inbox for a verification link')
        } else {
            await initiateEmailUpdate(email)
            setEmailChangeMessage('Verification email sent to ' + email)
        }
    }

    const handleSignOut = async () => {
        try {
            await signOut(auth)
        } catch (error) {
            console.error('Error signing out:', error)
        }
    }

    const handleShowSignOutDialog = () => {
        setShowSignOutDialog(true)
    }

    const handleStripeConnect = async () => {
        try {
            setIsStripeLoading(true)
            const createStripeHostedOnboardingLink = httpsCallable(functions, 'createStripeHostedOnboardingLink')
            const { data } = await createStripeHostedOnboardingLink({
                stripeAccountId: userData?.stripeAccountId
            })
            
            if (data.url) {
                window.location.href = data.url
                return
            }
            setIsStripeLoading(false)
        } catch (error) {
            console.error('Error creating Stripe account link:', error)
            setIsStripeLoading(false)
        }
    }


    const [isUploading, setIsUploading] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('Recommended: square image, at least 512×512px')
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (!file) return

        if (file.type.startsWith('image/')) {
            const tempUrl = URL.createObjectURL(file)
            const img = new Image()
            img.onload = async () => {
                URL.revokeObjectURL(tempUrl)
                await uploadImage(file)
            }
            img.src = tempUrl
        }
    }

    const resizeImage = async (file) => {
        const targetWidth = 840
        const reader = new FileReader()
        const canvas = document.createElement('canvas')
        const picaResizer = pica()
    
        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                const img = new Image()
                img.onload = async () => {
                    const aspectRatio = img.height / img.width
                    const targetHeight = targetWidth * aspectRatio
                    canvas.width = targetWidth
                    canvas.height = targetHeight
                    await picaResizer.resize(img, canvas)
                        .then(result => picaResizer.toBlob(result, 'image/jpeg', 0.90))
                        .then(blob => resolve(blob))
                        .catch(error => reject(error))
                }
                img.src = event.target.result
            }
            reader.readAsDataURL(file)
        })
    }

    const uploadImage = async (file) => {
        setIsUploading(true)
        try {
            const resizedImage = await resizeImage(file)
            const storage = getStorage()
            const storageRef = ref(storage, `initialIconFiles/${uid}/${file.name}`)
            await uploadBytes(storageRef, resizedImage)
            const userRef = doc(db, 'users', uid)
            await updateDoc(userRef, {
                initialIconFileName: file.name
            })
        } catch (error) {
            console.error('Upload failed', error)
            setUploadMessage('Upload failed. Please try again.')
        } finally {
            setIsUploading(false)
            setUploadMessage('Uploaded & processing. Refresh in about 1 minute to see the new icon.')
        }
    }

    if (!isMainAccountUser) {
        return (
            <div className="space-y-8">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-end gap-4">
                    <div>
                        <h1 className="text-3xl font-bold">Account</h1>
                        <p className="text-stone-500 dark:text-stone-400 text-sm mt-1">{auth.currentUser.email}</p>
                    </div>
                    <button
                        onClick={handleSignOut}
                        className="inline-flex items-center text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-3 py-1.5 border border-stone-300 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800 transition-colors"
                    >
                        <ArrowRightStartOnRectangleIcon className="w-4 h-4 mr-2 text-stone-500 dark:text-stone-400" />
                        Sign Out
                    </button>
                </div>
                <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-800 rounded-lg p-6">
                    <h2 className="text-lg font-semibold text-yellow-800 dark:text-yellow-200 mb-2">Limited Access</h2>
                    <p className="text-yellow-700 dark:text-yellow-300">
                        To edit account settings, contact the primary account owner: <span className="font-medium">{userData.emailAddress}</span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <>
            <ReauthDialog
                isOpen={showReauth}
                onClose={() => setShowReauth(false)}
                onConfirm={handleReauthComplete}
                mode={dialogMode}
            />
            
            <div className="space-y-8">          
                {/* Stripe Requirements Banner */}
                {needsStripeOnboarding && (
                    <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4 mb-4">
                        <div className="flex items-start sm:items-center justify-between flex-wrap gap-2 flex-col sm:flex-row">
                            <div className="flex-1">
                                <h3 className="font-medium text-yellow-800 dark:text-yellow-200">
                                    {userData?.stripeConnectHasCurrentlyDue === true 
                                        ? "Action required for your payment account" 
                                        : "Complete your payment account setup"}
                                </h3>
                                <p className="text-yellow-700 dark:text-yellow-300 text-sm">
                                    {userData?.stripeConnectHasCurrentlyDue === true 
                                        ? "To continue receiving payments, you need to complete your Stripe account setup."
                                        : "Set up your payment account to start accepting payments for your events."}
                                </p>
                            </div>
                            <button
                                onClick={handleStripeConnect}
                                disabled={isStripeLoading}
                                className="inline-flex items-center px-4 py-2 rounded-lg text-white bg-yellow-600 hover:bg-yellow-700
                                    transition duration-150 ease-in-out
                                    focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2
                                    disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isStripeLoading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        <CreditCardIcon className="w-5 h-5 mr-2" />
                                        {userData?.stripeConnectHasCurrentlyDue === true 
                                            ? "Complete Payment Setup" 
                                            : "Set Up Payment Account"}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                )}

                {/* Page Header */}
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-end gap-4">
                    <div>
                        <h1 className="text-3xl font-bold">Account</h1>
                        <p className="text-stone-500 dark:text-stone-400 text-sm mt-1">{userData.emailAddress}</p>
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                        <button
                            onClick={changeLoginEmail}
                            className="inline-flex items-center text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-3 py-1.5 border border-stone-300 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800 transition-colors"
                        >
                            <EnvelopeIcon className="w-4 h-4 mr-2 text-stone-500 dark:text-stone-400" />
                            Change Email
                        </button>
                        <button
                            onClick={handleShowSignOutDialog}
                            className="inline-flex items-center text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-3 py-1.5 border border-stone-300 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800 transition-colors"
                        >
                            <ArrowRightStartOnRectangleIcon className="w-4 h-4 mr-2 text-stone-500 dark:text-stone-400" />
                            Sign Out
                        </button>
                        
                        {emailChangeMessage && (
                            <div className="text-yellow-600 text-sm px-3 py-2 bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-100 dark:border-yellow-800 rounded">
                                {emailChangeMessage}
                            </div>
                        )}
                        
                        {isRevoking && (
                            <div className="inline-flex items-center px-4 py-2 bg-stone-100 dark:bg-stone-800 rounded-md text-sm text-stone-500 dark:text-stone-400">
                                <svg className="animate-spin h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Signing out of all sessions...
                            </div>
                        )}
                        
                        {revokeStatusMessage && (
                            <div className="text-yellow-600 text-sm px-3 py-2 bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-100 dark:border-yellow-800 rounded">
                                {revokeStatusMessage}
                            </div>
                        )}
                    </div>
                </div>

                {/* Section: Access Management */}
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 overflow-hidden shadow-sm">
                    <div className="px-6 py-4 border-b border-stone-200 dark:border-stone-700 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                        <div>
                            <h2 className="text-xl font-semibold">Access Management</h2>
                            <p className="text-stone-500 dark:text-stone-400 text-sm">
                                Manage who can access your account
                            </p>
                        </div>
                        <button
                            onClick={generateLoginCode}
                            disabled={isLoadingQr}
                            className="inline-flex items-center px-3 py-1.5 text-teal-600 bg-teal-50 hover:bg-teal-100 dark:bg-teal-900/20 dark:hover:bg-teal-900/30 border border-teal-200 dark:border-teal-800 rounded-md transition-colors w-auto whitespace-nowrap"
                        >
                            {isLoadingQr ? (
                                <>
                                    <svg className="animate-spin h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Generating...
                                </>
                            ) : (
                                "Generate Single Use Sign-in Link"
                            )}
                        </button>
                    </div>
                    <div className="px-6 py-4">
                        <AuthorizedUsersManager uid={uid} db={db} functions={functions} userData={userData} />
                    </div>
                </div>
                
                {/* Section: Account Settings */}
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 overflow-hidden shadow-sm">
                    <div className="px-6 py-4 border-b border-stone-200 dark:border-stone-700">
                        <h2 className="text-xl font-semibold">Account Settings</h2>
                        <p className="text-stone-500 dark:text-stone-400 text-sm">
                            {isConnectedToStripe 
                                ? "To maintain a consistent checkout experience, these settings are managed in Stripe" 
                                : "These details will appear on your checkout pages"}
                        </p>
                    </div>
                    
                    {isConnectedToStripe ? (
                        // Read-only table with Stripe edit links for connected users
                        <div className="px-6 py-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
                                {/* Name */}
                                <div className="flex items-center justify-between p-3 bg-stone-50 dark:bg-stone-900/60 rounded-lg">
                                    <div>
                                        <p className="text-sm font-medium text-stone-500 dark:text-stone-400">Name</p>
                                        <p className="font-medium">{userData.displayName}</p>
                                    </div>
                                    <a
                                        href="https://dashboard.stripe.com/settings/business-details"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900 font-medium text-sm inline-flex items-center"
                                    >
                                        Edit
                                    </a>
                                </div>

                                {/* Support */}
                                <div className="flex items-center justify-between p-3 bg-stone-50 dark:bg-stone-900/60 rounded-lg">
                                    <div>
                                        <p className="text-sm font-medium text-stone-500 dark:text-stone-400">Support</p>
                                        <p className="font-medium">{userData.supportEmailAddress || userData.emailAddress}</p>
                                    </div>
                                    <a
                                        href="https://dashboard.stripe.com/settings/business-details"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900 font-medium text-sm inline-flex items-center"
                                    >
                                        Edit
                                    </a>
                                </div>

                                {/* Currency */}
                                <div className="flex items-center justify-between p-3 bg-stone-50 dark:bg-stone-900/60 rounded-lg">
                                    <div>
                                        <p className="text-sm font-medium text-stone-500 dark:text-stone-400">Currency</p>
                                        <p className="font-medium">{userData.currency || 'USD'}</p>
                                    </div>
                                    <a
                                        href="https://dashboard.stripe.com/settings/payouts"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900 font-medium text-sm inline-flex items-center"
                                    >
                                        Edit
                                    </a>
                                </div>

                                {/* Time Zone */}
                                <div className="flex items-center justify-between p-3 bg-stone-50 dark:bg-stone-900/60 rounded-lg">
                                    <div>
                                        <p className="text-sm font-medium text-stone-500 dark:text-stone-400">Time Zone</p>
                                        <p className="font-medium">{userData.timeZone || 'America/New_York'}</p>
                                    </div>
                                    <a
                                        href="https://dashboard.stripe.com/settings/account"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900 font-medium text-sm inline-flex items-center"
                                    >
                                        Edit
                                    </a>
                                </div>

                                {/* Branding */}
                                <div className="flex items-center justify-between p-3 bg-stone-50 dark:bg-stone-900/60 rounded-lg col-span-1 md:col-span-2">
                                    <div className="flex items-center gap-3">
                                        <p className="text-sm font-medium text-stone-500 dark:text-stone-400 whitespace-nowrap">Branding</p>
                                        <div className="flex items-center gap-3 ml-4">
                                            <UserAccountIcon uid={uid} />
                                            <div className="flex gap-2">
                                                {userData.primaryColor && (
                                                    <div 
                                                        className="w-6 h-6 rounded-md border border-stone-200 dark:border-stone-800" 
                                                        style={{ backgroundColor: userData.primaryColor }}
                                                        title="Primary Color"
                                                    />
                                                )}
                                                {userData.secondaryColor && (
                                                    <div 
                                                        className="w-6 h-6 rounded-md border border-stone-200 dark:border-stone-800" 
                                                        style={{ backgroundColor: userData.secondaryColor }}
                                                        title="Button Color"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        href="https://dashboard.stripe.com/settings/branding"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900 font-medium text-sm inline-flex items-center"
                                    >
                                        Edit
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Editable form for users not connected to Stripe - Improved layout
                        <div className="px-6 py-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">Name</label>
                                        <input
                                            type="text"
                                            value={editableDisplayName}
                                            onChange={(e) => {
                                                setEditableDisplayName(e.target.value)
                                                handleDetailsChange()
                                            }}
                                            className="w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 shadow-sm"
                                            placeholder="Your name or business name"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">Support Email</label>
                                        <input
                                            type="email"
                                            value={editableSupportEmail}
                                            onChange={(e) => {
                                                setEditableSupportEmail(e.target.value)
                                                handleDetailsChange()
                                            }}
                                            className="w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 shadow-sm"
                                            placeholder="support@example.com"
                                        />
                                    </div>
                                    
                                    <div className="space-y-3">
                                        <label className="block text-sm font-medium text-stone-700 dark:text-stone-300">Branding Colors</label>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div className="bg-stone-50 dark:bg-stone-900 p-4 rounded-md border border-stone-200 dark:border-stone-700">
                                                <label className="block text-xs text-stone-500 dark:text-stone-400 mb-2">Primary color</label>
                                                <div className="flex items-center gap-3">
                                                    <div 
                                                        className="w-9 h-9 flex-shrink-0 rounded-md cursor-pointer border border-stone-300 dark:border-stone-600 overflow-hidden relative"
                                                        style={{ backgroundColor: editablePrimaryColor }}
                                                    >
                                                        <input
                                                            type="color"
                                                            value={editablePrimaryColor}
                                                            onChange={(e) => {
                                                                setEditablePrimaryColor(e.target.value)
                                                                handleDetailsChange()
                                                            }}
                                                            className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                                                        />
                                                    </div>
                                                    <input 
                                                        type="text"
                                                        value={editablePrimaryColor}
                                                        onChange={(e) => {
                                                            setEditablePrimaryColor(e.target.value)
                                                            handleDetailsChange()
                                                        }}
                                                        className="w-full max-w-[120px] rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 text-sm"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="bg-stone-50 dark:bg-stone-900 p-4 rounded-md border border-stone-200 dark:border-stone-700">
                                                <label className="block text-xs text-stone-500 dark:text-stone-400 mb-2">Accent color</label>
                                                <div className="flex items-center gap-3">
                                                    <div 
                                                        className="w-9 h-9 flex-shrink-0 rounded-md cursor-pointer border border-stone-300 dark:border-stone-600 overflow-hidden relative"
                                                        style={{ backgroundColor: editableSecondaryColor }}
                                                    >
                                                        <input
                                                            type="color"
                                                            value={editableSecondaryColor}
                                                            onChange={(e) => {
                                                                setEditableSecondaryColor(e.target.value)
                                                                handleDetailsChange()
                                                            }}
                                                            className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                                                        />
                                                    </div>
                                                    <input 
                                                        type="text"
                                                        value={editableSecondaryColor}
                                                        onChange={(e) => {
                                                            setEditableSecondaryColor(e.target.value)
                                                            handleDetailsChange()
                                                        }}
                                                        className="w-full max-w-[120px] rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 text-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-2">Brand Icon</label>
                                        <div className="bg-stone-50 dark:bg-stone-900 p-4 rounded-md border border-stone-200 dark:border-stone-700 flex flex-col items-center justify-center">
                                            <div className="mb-3">
                                                <UserAccountIcon uid={uid} />
                                            </div>
                                            
                                            {isUploading ? (
                                                <div className="inline-flex items-center px-3 py-1.5 bg-teal-50 dark:bg-teal-900/30 text-teal-600 dark:text-teal-400 rounded-md text-sm">
                                                    <svg className="animate-spin h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    Uploading...
                                                </div>
                                            ) : (
                                                <label className="inline-flex items-center px-3 py-1.5 bg-teal-50 dark:bg-teal-900/30 text-teal-600 dark:text-teal-400 rounded-md text-sm cursor-pointer hover:bg-teal-100 dark:hover:bg-teal-900/50 transition-colors">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                                    </svg>
                                                    Upload New Icon
                                                    <input
                                                        id="event-image"
                                                        type="file"
                                                        className="sr-only"
                                                        onChange={handleFileChange}
                                                        accept="image/*"
                                                    />
                                                </label>
                                            )}
                                            <p className="mt-2 text-xs text-stone-500 dark:text-stone-400">
                                                {uploadMessage}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mt-6 flex items-center justify-between border-t border-stone-200 dark:border-stone-700 pt-4">
                                <button
                                    onClick={saveUserDetails}
                                    disabled={!hasEditedDetails || isDetailsSaveLoading}
                                    className={`
                                        inline-flex items-center px-4 py-2 rounded-md text-white bg-teal-600
                                        focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2
                                        ${!hasEditedDetails || isDetailsSaveLoading
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-teal-700'}
                                    `}
                                >
                                    {isDetailsSaveLoading ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Saving...
                                        </>
                                    ) : "Save Changes"}
                                </button>
                                {detailsSaveMessage && (
                                    <span className={`text-sm ${detailsSaveMessage.includes('error') ? 'text-red-600' : 'text-teal-600'}`}>
                                        {detailsSaveMessage}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                
                {/* Section: Tax & Add-Ons */}
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 overflow-hidden shadow-sm">
                    <div className="px-6 py-4 border-b border-stone-200 dark:border-stone-700">
                        <h2 className="text-xl font-semibold">Tax & Add-Ons</h2>
                        <p className="text-stone-500 dark:text-stone-400 text-sm">
                            A line item with this description and percentage will be added to all checkout sessions
                        </p>
                    </div>
                    
                    <div className="px-6 py-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">Description</label>
                                    <input
                                        type="text"
                                        value={taxDescription}
                                        onChange={(e) => handleTaxDescriptionChange(e.target.value)}
                                        className="w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 shadow-sm"
                                        placeholder="e.g. Sales Tax, VAT, Service Fee"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">Percentage</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            value={taxPercentage}
                                            onChange={(e) => handleTaxPercentageChange(e.target.value)}
                                            className="w-24 rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 shadow-sm"
                                            placeholder="0.0"
                                        />
                                        <span className="ml-2 text-stone-500 dark:text-stone-400">%</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="bg-stone-50 dark:bg-stone-900 p-4 rounded-md border border-stone-200 dark:border-stone-700">
                                <p className="text-sm font-medium text-stone-700 dark:text-stone-300 mb-3">Calculation Method</p>
                                
                                <div className="space-y-3">
                                    <label className="flex items-start p-3 border rounded-md border-stone-200 dark:border-stone-700 bg-white dark:bg-stone-800 cursor-pointer hover:bg-stone-50 dark:hover:bg-stone-700">
                                        <input
                                            type="radio"
                                            name="tax-type"
                                            checked={taxIsAddedOn === true}
                                            onChange={() => handleTaxAddOnStatusChange(true)}
                                            className="h-4 w-4 mt-0.5 text-teal-600 focus:ring-teal-500 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900"
                                        />
                                        <div className="ml-3">
                                            <span className="block font-medium text-sm">Add to order total</span>
                                            <span className="block text-xs text-stone-500 dark:text-stone-400">
                                                Will be added on top of the ticket price
                                            </span>
                                        </div>
                                    </label>
                                    
                                    <label className="flex items-start p-3 border rounded-md border-stone-200 dark:border-stone-700 bg-white dark:bg-stone-800 cursor-pointer hover:bg-stone-50 dark:hover:bg-stone-700">
                                        <input
                                            type="radio"
                                            name="tax-type"
                                            checked={taxIsAddedOn === false}
                                            onChange={() => handleTaxAddOnStatusChange(false)}
                                            className="h-4 w-4 mt-0.5 text-teal-600 focus:ring-teal-500 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900"
                                        />
                                        <div className="ml-3">
                                            <span className="block font-medium text-sm">Price-inclusive display only</span>
                                            <span className="block text-xs text-stone-500 dark:text-stone-400">
                                                Display the tax amount already included in the ticket price
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mt-6 flex items-center justify-between border-t border-stone-200 dark:border-stone-700 pt-4">
                            <button
                                onClick={saveTaxSettings}
                                disabled={!hasEditedTaxSettings || isTaxSaveLoading}
                                className={`
                                    inline-flex items-center px-4 py-2 rounded-md text-white bg-teal-600
                                    focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2
                                    ${!hasEditedTaxSettings || isTaxSaveLoading
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-teal-700'}
                                `}
                            >
                                {isTaxSaveLoading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Saving...
                                    </>
                                ) : "Save Changes"}
                            </button>
                            {taxSaveMessage && (
                                <span className={`text-sm ${taxSaveMessage.includes('error') ? 'text-red-600' : 'text-teal-600'}`}>
                                    {taxSaveMessage}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Sign Out Dialog */}
            <Dialog open={showSignOutDialog} onClose={() => setShowSignOutDialog(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-md rounded-lg bg-white dark:bg-stone-800 p-6 w-full shadow-xl border border-stone-200 dark:border-stone-700">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">
                                Sign out
                            </Dialog.Title>
                            <button onClick={() => setShowSignOutDialog(false)} className="text-stone-500">
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-2 mt-4">
                            <div className="space-y-2">
                                <button
                                    onClick={() => {
                                        handleSignOut()
                                        setShowSignOutDialog(false)
                                    }}
                                    className="w-full flex items-center justify-between bg-white dark:bg-stone-700 border border-stone-300 dark:border-stone-600 rounded-md py-3 px-4 text-sm font-medium text-stone-700 dark:text-stone-200 hover:bg-stone-50 dark:hover:bg-stone-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
                                >
                                    <div className="flex items-center">
                                        <div className="text-left">
                                            <div className="font-semibold">Sign out from this device</div>
                                            <div className="text-sm text-stone-500 dark:text-stone-400 mt-1">Only log out from your current browser</div>
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="space-y-2">
                                <button
                                    onClick={() => {
                                        revoke()
                                        setShowSignOutDialog(false)
                                    }}
                                    className="w-full flex items-center justify-between bg-white dark:bg-stone-700 border border-red-200 dark:border-red-900 rounded-md py-3 px-4 text-sm font-medium text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30 focus:outline-none focus:ring-2 focus:ring-red-500"
                                >
                                    <div className="flex items-center">
                                        <div className="text-left">
                                            <div className="font-semibold">Sign out from all devices</div>
                                            <div className="text-sm text-stone-500 dark:text-stone-400 mt-1">Log everyone out from all sessions on all devices</div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    )
}