import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { collection, query, where, getDocs, getAggregateFromServer, sum, getDoc, doc, updateDoc } from "firebase/firestore"
import { TicketIcon, BoltIcon, CodeBracketIcon, PencilIcon, EyeIcon } from '@heroicons/react/24/outline'
import DuplicateButton from '../components/DuplicateButton'
import CompTicketButton from '../components/CompTicketButton'
import AttendeesMenu from '../components/AttendeesMenu'

export default function Event({ uid, events, auth, db, functions, chargesEnabled }) {
    const { eventId } = useParams()
    const [event, setEvent] = useState(events.find(e => e.paymentLinkId === eventId))
    const [loading, setLoading] = useState(!event)
    const [totalSales, setTotalSales] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const [timeSlots, setTimeSlots] = useState([])
    const [showFutureOnly, setShowFutureOnly] = useState(true)
    const [showEmbedDialog, setShowEmbedDialog] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [downloadType, setDownloadType] = useState(null)
    
    // Add state for embed dialog
    const [buttonText, setButtonText] = useState('Register')
    const [buttonColor, setButtonColor] = useState('#1c1917')
    const [buttonRadius, setButtonRadius] = useState('4')
    const [embedTicketsOnly, setEmbedTicketsOnly] = useState(true)
    const [copyButtonText, setCopyButtonText] = useState('Copy Embed Code')

    useEffect(() => {
        if (event?.eventCheckinType === 'tickets') {
            setButtonText('Get Tickets')
        } else {
            setButtonText('Register')
        }
    }, [event])

    useEffect(() => {
        // Fetch total sales using aggregate query
        const fetchTotalSales = async () => {
            const q = query(
                collection(db, "orders"),
                where("accountId", "==", uid),
                where("paymentLinkId", "==", eventId)
            )

            try {
                const snapshot = await getAggregateFromServer(q, {
                    totalAmount: sum('orderTotal'),
                    totalFees: sum('ticketFee')
                })
                setTotalSales(snapshot.data().totalAmount || 0)
                setTotalFees(snapshot.data().totalFees || 0)
            } catch (error) {
                console.error("Error fetching totals:", error)
            }
        }

        fetchTotalSales()
    }, [db, uid, eventId])

    useEffect(() => {
        const fetchTimeSlots = async () => {
            if (!event?.timeSlotPatterns?.length) return
            try {
                const slotsRef = collection(db, "events", eventId, "slots")
                const querySnapshot = await getDocs(slotsRef)
                const slots = querySnapshot.docs
                    .map(doc => {
                        const [date, label] = doc.id.split('@')
                        return {
                            id: doc.id,
                            date,
                            label,
                            ...doc.data()
                        }
                    })
                    .filter(slot => slot.issued > 0)
                setTimeSlots(slots)
            } catch (error) {
                console.error("Full error:", error)
            }
        }
        fetchTimeSlots()
    }, [db, eventId, event?.timeSlotPatterns])

    useEffect(() => {
        const fetchArchivedEvent = async () => {
            if (event) return // Skip if event already exists

            try {
                const eventDoc = await getDoc(doc(db, "events", eventId))
                if (eventDoc.exists()) {
                    setEvent({ ...eventDoc.data(), paymentLinkId: eventId })
                } else {
                    // Event truly doesn't exist
                    setEvent(null)
                }
            } catch (error) {
                console.error("Error fetching archived event:", error)
                setEvent(null)
            } finally {
                setLoading(false)
            }
        }

        fetchArchivedEvent()
    }, [eventId, db, event])

    const toggleSalesStatus = async () => {
        try {
            if (!chargesEnabled && event.ticketTypes.some(type => type.price > 0)) {
                alert('Go to account settings to enable payments')
                return
            }
            
            const eventRef = doc(db, 'events', eventId)
            await updateDoc(eventRef, {
                isTicketingLive: !event.isTicketingLive
            })
            setEvent({
                ...event,
                isTicketingLive: !event.isTicketingLive
            })
        } catch (error) {
            console.error('Error toggling sales status:', error)
        }
    }

    const archiveEvent = async () => {
        try {
            const eventRef = doc(db, 'events', eventId)
            await updateDoc(eventRef, {
                archived: true,
                isTicketingLive: false
            })
            setEvent({
                ...event,
                archived: true,
                isTicketingLive: false
            })
        } catch (error) {
            console.error('Error archiving event:', error)
        }
    }

    if (loading) {
        return <div>Loading...</div>
    }

    if (!event) {
        return <div>Event not found</div>
    }

    return (
        <div className="space-y-6">
            <div className="space-y-4 pb-4">
                <h1 className="text-3xl font-bold">{event.eventName}</h1>
                <div className="flex flex-wrap items-center justify-between gap-3">
                    <div className="flex items-center gap-3">
                        <div className="flex items-center gap-2">
                            <span className={`inline-flex h-3 w-3 rounded-full ${event.isTicketingLive ? 'bg-green-500' : 'bg-stone-400'}`}></span>
                            <span className="text-sm text-stone-600 dark:text-stone-400">{event.isTicketingLive ? 'Active' : 'Paused'}</span>
                        </div>
                        <div className="flex gap-2">
                            <button 
                                onClick={toggleSalesStatus}
                                className={`text-stone-700 dark:text-stone-300 border border-stone-400 dark:border-stone-600 rounded-md px-2 py-1 hover:bg-stone-50 dark:hover:bg-stone-800 ${event.isTicketingLive ? '' : 'bg-teal-600 text-white border-teal-600 hover:bg-teal-700'}`}
                            >
                                {event.isTicketingLive ? 'Pause' : 'Resume Sales'}
                            </button>
                            {!event.archived && (
                                <button 
                                    onClick={archiveEvent}
                                    className="text-stone-700 dark:text-stone-300 border border-stone-400 dark:border-stone-600 rounded-md px-2 py-1 hover:bg-stone-50 dark:hover:bg-stone-800"
                                >
                                    Archive
                                </button>
                            )}
                        </div>
                    </div>
                    
                    {/* Action Buttons */}
                    <div className="flex flex-wrap items-center gap-2">
                        <a 
                            href={`https://tkt.page/${eventId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
                            style={{ display: isDownloading ? 'none' : 'inline-flex' }}
                        >
                            <EyeIcon className="h-3.5 w-3.5" />
                            <span>View</span>
                        </a>
                        <button 
                            onClick={() => setShowEmbedDialog(true)}
                            className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
                            style={{ display: isDownloading ? 'none' : 'inline-flex' }}
                        >
                            <CodeBracketIcon className="h-3.5 w-3.5" />
                            <span>Embed</span>
                        </button>
                        <Link 
                            to={`/events/${eventId}/edit`}
                            className="inline-flex items-center gap-1 text-stone-700 dark:text-stone-300 hover:text-stone-800 dark:hover:text-stone-200 px-2 py-1 border border-stone-400 dark:border-stone-600 rounded-md hover:bg-stone-50 dark:hover:bg-stone-800"
                            style={{ display: isDownloading ? 'none' : 'inline-flex' }}
                        >
                            <PencilIcon className="h-3.5 w-3.5" />
                            <span>Edit</span>
                        </Link>
                        {!isDownloading && <DuplicateButton eventId={eventId} functions={functions} />}
                        {!isDownloading && <CompTicketButton eventId={eventId} events={events} functions={functions} />}
                        <AttendeesMenu 
                            auth={auth} 
                            uid={uid} 
                            eventId={eventId} 
                            issued={event.issued} 
                            isDownloading={isDownloading}
                            setIsDownloading={setIsDownloading}
                            downloadType={downloadType}
                            setDownloadType={setDownloadType}
                        />
                    </div>
                </div>
            </div>

            {/* Stats Row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Attendees Stats */}
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 p-4">
                    <div className="flex justify-between items-center mb-2">
                        <h2 className="text-lg font-medium flex items-center gap-2">
                            <TicketIcon className="h-5 w-5 text-teal-600 dark:text-teal-500" />
                            Attendees
                        </h2>
                        <div className="text-2xl font-bold">
                            {event.issued || 0}
                            {event.capacity > 0 && !event.timeSlotPatterns?.length && (
                                <span className="ml-1 text-base font-normal text-stone-500 dark:text-stone-400">
                                    / {event.capacity}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                            <Link
                                to={`/events/${eventId}/registrations`}
                                className="text-sm text-teal-600 hover:text-teal-700 dark:hover:text-teal-500"
                            >
                                View List
                            </Link>
                        </div>
                        {event.capacity > 0 && !event.timeSlotPatterns?.length && (
                            <div className="h-1.5 bg-stone-100 dark:bg-stone-700 rounded-full overflow-hidden w-1/3">
                                <div
                                    className="h-full bg-teal-500 rounded-full transition-all duration-500"
                                    style={{ width: `${Math.min(100, (event.issued / event.capacity) * 100)}%` }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Sales Stats */}
                <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 p-4">
                    <div className="flex justify-between items-center mb-2">
                        <h2 className="text-lg font-medium flex items-center gap-2">
                            <BoltIcon className="h-5 w-5 text-teal-600 dark:text-teal-500" />
                            Sales
                        </h2>
                        <div className="text-2xl font-bold">
                            {new Intl.NumberFormat(undefined, {
                                style: 'currency',
                                currency: event.currency || 'USD'
                            }).format((totalSales - totalFees) / 100)}
                            <span className="ml-1 text-xs font-normal text-stone-500 dark:text-stone-400">net</span>
                        </div>
                    </div>
                    <Link
                        to={`/orders?event=${eventId}`}
                        className="text-sm text-teal-600 hover:text-teal-700 dark:hover:text-teal-500"
                    >
                        View Orders
                    </Link>
                </div>
            </div>


            {/* Main Content Area */}
            <div className="space-y-6">
                {/* Ticket Types or Time Slots */}
                <div className="space-y-3">
                    <div className="flex items-center justify-between">
                        {event.timeSlotPatterns?.length > 0 && (
                            <label className="flex items-center space-x-2 text-sm text-stone-500 dark:text-stone-400">
                                <input
                                    type="checkbox"
                                    checked={showFutureOnly}
                                    onChange={(e) => setShowFutureOnly(e.target.checked)}
                                    className="rounded border-stone-300 dark:border-stone-700 text-teal-600 focus:ring-teal-500 focus:ring-1"
                                />
                                <span>Future only</span>
                            </label>
                        )}
                    </div>

                    {event.issued > 0 &&
                        <div className="bg-white dark:bg-stone-800 rounded-lg border border-stone-200 dark:border-stone-700 overflow-hidden">
                            {/* Slots or Ticket Types */}
                            {event.timeSlotPatterns?.length > 0 ? (
                                <table className="min-w-full divide-y divide-stone-200 dark:divide-stone-700">
                                    <thead className="bg-stone-50 dark:bg-stone-800/60">
                                        <tr>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-stone-500 dark:text-stone-400 uppercase tracking-wider">Slot</th>
                                            <th className="px-4 py-3 text-right text-xs font-medium text-stone-500 dark:text-stone-400 uppercase tracking-wider">Attendees</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-stone-800 divide-y divide-stone-200 dark:divide-stone-700">
                                        {timeSlots
                                            .filter(slot => !showFutureOnly || new Date(slot.date) >= new Date().setHours(0, 0, 0, 0))
                                            .map((slot) => (
                                                <tr key={slot.id}>
                                                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                                                        {new Date(slot.date).toLocaleDateString(undefined, {
                                                            weekday: 'short',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })} {slot.label}
                                                    </td>
                                                    <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium">
                                                        {slot.issued}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <table className="min-w-full divide-y divide-stone-200 dark:divide-stone-700">
                                    <thead className="bg-stone-50 dark:bg-stone-800/60">
                                        <tr>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-stone-500 dark:text-stone-400 uppercase tracking-wider">Ticket Type</th>
                                            <th className="px-4 py-3 text-right text-xs font-medium text-stone-500 dark:text-stone-400 uppercase tracking-wider">Issued</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-stone-800 divide-y divide-stone-200 dark:divide-stone-700">
                                        {event.ticketTypes
                                            .filter(type => (event[`issued_${type.productId}`] || 0) > 0)
                                            .map((type) => {
                                                const issuedCount = event[`issued_${type.productId}`] || 0
                                                const inCartsCount = event[`inCarts_${type.productId}`] || 0

                                                return (
                                                    <tr key={type.id}>
                                                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                                                            {type.name || event.eventName}
                                                        </td>
                                                        <td className="px-4 py-3 whitespace-nowrap text-sm text-right">
                                                            <span className="font-medium">{issuedCount}</span>
                                                            {type.capacity > 0 && (
                                                                <span className="text-stone-500 dark:text-stone-400 ml-1">
                                                                    / {type.capacity}
                                                                </span>
                                                            )}
                                                            {inCartsCount > 0 && (
                                                                <span className="text-xs text-stone-500 dark:text-stone-400 ml-1">
                                                                    (+{inCartsCount} in carts)
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    }
                </div>
            </div>

            {/* Embed Dialog */}
            {showEmbedDialog && (
                <div className="fixed inset-0 bg-black/30 dark:bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-stone-800 rounded-lg shadow-xl max-w-lg w-full p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-medium">Embed on your website</h2>
                            <button 
                                onClick={() => setShowEmbedDialog(false)}
                                className="text-stone-500 dark:text-stone-400 hover:text-stone-700 dark:hover:text-stone-200"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>                        
                        <p className="mb-4 text-stone-600 dark:text-stone-300">
                            A button on your site will open the event in a sidebar. Paste the embed code into your website where you want the button to appear.
                        </p>
                        <div className="mt-4 space-y-6">
                                <div className="space-y-1">
                                    <input 
                                        type="checkbox"
                                        checked={embedTicketsOnly}
                                        onChange={(e) => setEmbedTicketsOnly(e.target.checked)}
                                        className="rounded border-stone-300 dark:border-stone-700 text-teal-600 focus:ring-teal-500 focus:ring-1 mr-2"
                                    />
                                    <label className="text-sm text-stone-700 dark:text-stone-300">{`Show ${event.eventCheckinType === 'tickets' ? 'tickets list' : 'registrations options'} only`}</label>
                                </div>
                        </div>
                        <div className="mt-4 space-y-6">
                            <div className="grid grid-cols-3 gap-4 border border-stone-200 dark:border-stone-700 p-4 rounded-lg bg-stone-50 dark:bg-stone-900">
                                <div className="space-y-1">
                                    <label className="block text-sm text-stone-700 dark:text-stone-300">Button text</label>
                                    <input 
                                        type="text" 
                                        value={buttonText}
                                        onChange={(e) => setButtonText(e.target.value)}
                                        className="w-full px-3 py-2 rounded-md border border-stone-300 dark:border-stone-700 bg-white dark:bg-stone-900"
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="block text-sm text-stone-700 dark:text-stone-300">Button radius</label>
                                    <input 
                                        type="number"
                                        value={buttonRadius}
                                        onChange={(e) => setButtonRadius(e.target.value)}
                                        className="w-full px-3 py-2 rounded-md border border-stone-300 dark:border-stone-700 bg-white dark:bg-stone-900"
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="block text-sm text-stone-700 dark:text-stone-300">Button color</label>
                                    <div className="flex items-center gap-3">
                                        <div 
                                            className="w-9 h-9 flex-shrink-0 rounded-md cursor-pointer border border-stone-300 dark:border-stone-600 overflow-hidden relative"
                                            style={{ backgroundColor: buttonColor }}
                                        >
                                            <input
                                                type="color"
                                                value={buttonColor}
                                                onChange={(e) => setButtonColor(e.target.value)}
                                                className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                                            />
                                        </div>
                                        <input 
                                            type="text"
                                            value={buttonColor}
                                            onChange={(e) => setButtonColor(e.target.value)}
                                            className="w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900 text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button 
                                onClick={() => {
                                    navigator.clipboard.writeText(`<script src="https://tkt.page/${eventId}/embed.js?buttonText=${encodeURIComponent(buttonText)}&buttonColor=${encodeURIComponent(buttonColor)}&buttonTextColor=${encodeURIComponent('#ffffff')}&buttonRadius=${encodeURIComponent(buttonRadius)}&embedTicketsOnly=${encodeURIComponent(embedTicketsOnly ? 1 : 0)}"></script>`)
                                    setCopyButtonText(`
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline-block mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                        Copied!
                                    `)
                                    setTimeout(() => {
                                        setCopyButtonText('Copy Embed Code')
                                    }, 2000)
                                }}
                                id="copy-custom-button"
                                className="block w-full items-center gap-1 text-teal-600 dark:text-teal-400 hover:text-teal-800 dark:hover:text-teal-200 px-3 py-2 border border-teal-600 dark:border-teal-400 rounded-md"
                                dangerouslySetInnerHTML={{ __html: copyButtonText }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
} 