import { useState, useRef, useEffect, useCallback } from 'react'

function PlacesAPISearch({ onSelect, defaultValue = '', initialLocationName = '', setValue }) {
  // Track both the current input value and the display value separately
  const [inputValue, setInputValue] = useState(defaultValue || '')
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [hasSelectedPlace, setHasSelectedPlace] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState(null)
  
  const containerRef = useRef(null)
  const debounceTimerRef = useRef(null)
  const stateRef = useRef({ inputValue, hasSelectedPlace, selectedPlace })
  
  useEffect(() => {
    stateRef.current = { inputValue, hasSelectedPlace, selectedPlace }
  }, [inputValue, hasSelectedPlace, selectedPlace])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setShowSuggestions(false)
      
      const { inputValue, hasSelectedPlace } = stateRef.current
      
      // Only update if:
      // 1. We haven't selected a place from dropdown
      // 2. The input value doesn't match the defaultValue (to prevent override on initial focus/blur)
      // 3. We have text in the input
      if (!hasSelectedPlace && inputValue.trim() && inputValue !== defaultValue) {
        const manualPlaceDetails = {
          locationName: inputValue.trim(),
          fullAddress: '', // Keep fullAddress empty for manual entries
          coordinates: null,
          placeId: null
        }
        
        onSelect(manualPlaceDetails)
      }
    }, 200)
  }, [defaultValue, onSelect])

  const fetchSuggestions = async (query) => {
    if (!query || query.trim().length < 3) {
      setSuggestions([])
      return
    }

    setLoading(true)
    setError(null)

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const searchUrl = `https://places.googleapis.com/v1/places:searchText`

    try {
      const response = await fetch(searchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': apiKey,
          'X-Goog-FieldMask': 'places.displayName,places.formattedAddress,places.location,places.id'
        },
        body: JSON.stringify({
          textQuery: query,
          languageCode: 'en'
        })
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error('Places API Error:', errorText)
        throw new Error(`Places API returned ${response.status}: ${errorText}`)
      }

      const data = await response.json()
      
      if (data.places && data.places.length > 0) {
        setSuggestions(data.places)
      } else {
        setSuggestions([])
      }
    } catch (err) {
      console.error('Error fetching places:', err)
      setError(err.message || 'Error fetching places')
      setSuggestions([])
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const query = e.target.value
    setInputValue(query)
    setShowSuggestions(true)
    
    // Only update form values if we're not in selected place mode
    if (!hasSelectedPlace && setValue) {
      // Only update the name initially - we'll clear address/coordinates only if user doesn't select a place
      setValue('eventLocationName', query)
      
      // Clear the address field when typing manually, ONLY if the text is substantially different
      // This helps prevent losing data when just making small edits
      if (query.trim().length < 3 || inputValue.trim().toLowerCase() !== query.trim().toLowerCase()) {
        setValue('eventFullAddress', '')
        setValue('eventLocationCoordinates', null)
        setValue('eventPlaceId', null)
      }
    }

    // Clear previous timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }

    // Set new timer
    debounceTimerRef.current = setTimeout(() => {
      fetchSuggestions(query)
    }, 300)
  }

  // Handle suggestion selection
  const handleSelectSuggestion = (place) => {
    const locationName = place.displayName?.text || ''
    
    const coordinates = place.location ? {
      lat: place.location.latitude,
      lng: place.location.longitude
    } : null
    
    const placeDetails = {
      fullAddress: place.formattedAddress || '',
      locationName: locationName,
      coordinates: coordinates,
      placeId: place.id || ''
    }
    
    setSelectedPlace(place)
    
    onSelect(placeDetails)
    
    setShowSuggestions(false)
    setHasSelectedPlace(true)
    setInputValue('')
  }

  const handleRemovePlace = () => {
    setSelectedPlace(null)
    setHasSelectedPlace(false)
    setInputValue('')
    
    if (setValue) {
      setValue('eventLocationName', '')
      setValue('eventFullAddress', '')
      setValue('eventLocationCoordinates', null)
      setValue('eventPlaceId', null)
    }
    
    onSelect({
      locationName: '',
      fullAddress: '',
      coordinates: null,
      placeId: null
    })
  }

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue.trim() && initialLocationName) {
        setSelectedPlace({
          formattedAddress: defaultValue,
          displayName: { text: initialLocationName }
        })
        setHasSelectedPlace(true)
        setInputValue('')
      } else {
        setInputValue(defaultValue)
      }
    }
  }, [defaultValue, initialLocationName])

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        const { hasSelectedPlace } = stateRef.current
        if (hasSelectedPlace) {
          return
        }
        handleBlur()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleBlur])

  
  return (
    <div className="relative" ref={containerRef}>
      {hasSelectedPlace && selectedPlace ? (
        <div className="flex items-center p-2 bg-stone-100 dark:bg-stone-800 rounded-sm border border-stone-300 dark:border-stone-700">
          <div className="flex-grow">
            <div className="font-medium">{selectedPlace.displayName?.text}</div>
            <div className="text-xs text-stone-500">{selectedPlace.formattedAddress}</div>
          </div>
          <button 
            type="button"
            onClick={handleRemovePlace}
            className="ml-2 text-stone-400 hover:text-stone-600 dark:hover:text-stone-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
      ) : (
        <>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder="Search for a location or enter venue name"
            className="block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ring-stone-300 dark:ring-stone-700 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900"
          />
          
          {loading && (
            <div className="text-xs text-stone-500 mt-1">Searching locations...</div>
          )}
          
          {error && (
            <div className="text-xs text-red-500 mt-1">{error}</div>
          )}
          
          {showSuggestions && suggestions.length > 0 && (
            <ul className="mt-1 z-10 absolute w-full bg-white dark:bg-stone-800 border border-stone-300 dark:border-stone-700 rounded-md shadow-lg max-h-60 overflow-auto">
              {suggestions.map((place) => (
                <li 
                  key={place.id} 
                  onClick={() => handleSelectSuggestion(place)}
                  className="cursor-pointer px-4 py-2 hover:bg-stone-100 dark:hover:bg-stone-700"
                >
                  <div className="font-medium">{place.displayName?.text}</div>
                  <div className="text-xs text-stone-500">{place.formattedAddress}</div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  )
}

export default PlacesAPISearch 