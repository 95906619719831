import { useState, useEffect } from 'react'
import { CalendarIcon } from '@heroicons/react/24/outline'

const CalendarInfo = ({ calendarData, eventName, locationName, fullAddress }) => {
    // Add state for dropdown visibility - moved above the conditional return
    const [showDropdown, setShowDropdown] = useState(false)
    
    // Add click outside handler - moved above the conditional return
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDropdown && !event.target.closest('.calendar-dropdown-container')) {
                setShowDropdown(false)
            }
        }
        
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showDropdown])
    
    if (!calendarData) return null
    
    // Format dates for calendar
    const formatDateTime = (date, time) => {
        // Format: YYYYMMDDTHHMMSS
        const [year, month, day] = date.split('-')
        const [hours, minutes] = time.split(':')
        return `${year}${month}${day}T${hours}${minutes}00`
    }
    
    // Format date for display in a user-friendly way
    const formatDisplayDate = () => {
        // Create Date objects for start and end
        const startDate = new Date(`${calendarData.startDate}T${calendarData.startTime}`)
        const endDate = new Date(`${calendarData.endDate}T${calendarData.endTime}`)
        
        // Format options
        const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' }
        const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true }
        
        // Format date strings
        const startDateStr = startDate.toLocaleDateString('en-US', dateOptions)
        const startTimeStr = startDate.toLocaleTimeString('en-US', timeOptions).toLowerCase()
        const endTimeStr = endDate.toLocaleTimeString('en-US', timeOptions).toLowerCase()
        
        // Check if start and end dates are the same
        const sameDay = calendarData.startDate === calendarData.endDate
        
        if (sameDay) {
            return `${startDateStr}, ${startTimeStr} - ${endTimeStr}`
        } else {
            const endDateStr = endDate.toLocaleDateString('en-US', dateOptions)
            return `${startDateStr}, ${startTimeStr} - ${endDateStr}, ${endTimeStr}`
        }
    }
    
    const startDateTime = formatDateTime(calendarData.startDate, calendarData.startTime)
    const endDateTime = formatDateTime(calendarData.endDate, calendarData.endTime)
    
    // Create Google Calendar URL
    const createGoogleCalendarUrl = () => {
        const baseUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
        const params = new URLSearchParams({
            text: eventName || 'Event',
            dates: `${startDateTime}/${endDateTime}`,
            details: `Ticket from ${eventName}`,
            location: fullAddress || locationName || '',
            ctz: calendarData.timeZone || 'UTC'
        })
        return `${baseUrl}&${params.toString()}`
    }
    
    const generateICSFile = () => {
        // Format date for ICS file (YYYYMMDDTHHMMSS)
        const formatICSDate = (dateTime) => {
            // Format without Z suffix to keep original time zone
            return dateTime.replace(/[^0-9T]/g, '')
        }
        
        const startICS = formatICSDate(startDateTime)
        const endICS = formatICSDate(endDateTime)
        
        // Get time zone identifier
        const tzid = calendarData.timeZone || 'UTC'
        
        // Create the ICS content with TZID parameter
        const icsContent = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'CALSCALE:GREGORIAN',
            'METHOD:PUBLISH',
            'BEGIN:VEVENT',
            `SUMMARY:${eventName || 'Event'}`,
            `DTSTART;TZID=${tzid}:${startICS}`,
            `DTEND;TZID=${tzid}:${endICS}`,
            `LOCATION:${fullAddress || locationName || ''}`,
            `DESCRIPTION:Ticket from ${eventName}`,
            'STATUS:CONFIRMED',
            `CREATED:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d+/g, '')}Z`,
            'END:VEVENT',
            'END:VCALENDAR'
        ].join('\r\n')
        
        // Create a blob and trigger download
        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${eventName || 'event'}.ics`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    
    return (
        <div className="my-4">
            {/* Calendar button */}
            <div className="relative calendar-dropdown-container">
                <button 
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-lg py-2.5 px-4 flex items-center justify-center hover:bg-neutral-50 dark:hover:bg-neutral-700 transition-colors"
                >
                    <CalendarIcon className="h-4 w-4 text-neutral-600 dark:text-neutral-400 mr-2" />
                    <span className="text-neutral-700 dark:text-neutral-300 font-medium">
                        Add to Calendar
                    </span>
                </button>
                
                {/* Date display below button */}
                <div className="text-center mt-2">
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        {formatDisplayDate()}
                    </span>
                </div>
                
                {showDropdown && (
                    <div className="absolute z-10 mt-1 bg-white dark:bg-neutral-800 shadow-lg rounded-lg border border-neutral-200 dark:border-neutral-700 py-1 w-full">
                        <button 
                            onClick={() => {
                                generateICSFile()
                                setShowDropdown(false)
                            }}
                            className="w-full text-left px-4 py-3 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-700 transition-colors"
                        >
                            Add to Any Calendar
                        </button>
                        <div className="border-t border-neutral-200 dark:border-neutral-700"></div>
                        <a 
                            href={createGoogleCalendarUrl()} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick={() => setShowDropdown(false)}
                            className="block w-full text-left px-4 py-3 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-700 transition-colors"
                        >
                            Add to Google Calendar
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CalendarInfo 